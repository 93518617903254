import React from 'react';

interface ActiveMapSpeedIconProps {
    speed: number;
}

type SignalStrength =
    'no connection' |
    'one bar' |
    'two bar' |
    'three bar' |
    'four bar';

const signalStrengthForSpeed = (speed?: number): SignalStrength => {
    if (!speed) return 'no connection';
    if (speed < 50) return 'four bar';
    if (speed < 200) return 'three bar';
    if (speed < 1000) return 'two bar';
    return 'one bar';
}

const ActiveMapSpeedIcon = (props: ActiveMapSpeedIconProps) => {

    const oneBar = () => {
        return <img src="/assets/signal-strength/signal-strength-blue-1.png" title={`${props.speed}kbps`} />;
    }

    const twoBar = () => {
        return <img src="/assets/signal-strength/signal-strength-blue-2.png" title={`${props.speed}kbps`} />;
    }

    const threeBar = () => {
        return <img src="/assets/signal-strength/signal-strength-blue-3.png" title={`${props.speed}kbps`} />;
    }

    const fourBar = () => {
        return <img src="/assets/signal-strength/signal-strength-blue-4.png" title={`${props.speed}kbps`} />;
    };

    const noSignal = () => {
        return <img src="/assets/signal-strength/signal-strength-blue-no-signal.png" title={`${props.speed}kbps`} />;
    };

    const signalStrength = signalStrengthForSpeed(props.speed);
    switch (signalStrength) {
        case 'one bar': return oneBar();
        case 'two bar': return twoBar();
        case 'three bar': return threeBar();
        case 'four bar': return fourBar();
        case 'no connection': return noSignal();
        default: return <React.Fragment />
    }
};

export default ActiveMapSpeedIcon;
