import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actionSetSelectedContinent } from '../../store/Map/MapSelection/actions';
import { ListingGroup } from '../../store/Map/MapSelection/model';
import { selectContinentListings, selectSelectedContinent } from '../../store/Map/MapSelection/selectors';
import { SegmentContainer } from './search-container';

const SearchResultsContinentFilter = () => {
    const dispatch = useDispatch();
    const selectedContinent = useSelector(selectSelectedContinent);
    const continentsDictionary = useSelector(selectContinentListings);
    const setSelectedContinent = (continent: ListingGroup | undefined) =>
        dispatch(actionSetSelectedContinent(continent));

    if (!continentsDictionary || selectedContinent) {
        return <React.Fragment />;
    }

    return (
        <FilterContainer>
            <ContinentFilterTitle>Filter by continent</ContinentFilterTitle>
            <ContinentFilterList>
                {Array.from(continentsDictionary.values()).map((t) => {
                    if (!t.continent) return <React.Fragment />;

                    return (
                        <ContinentFilterItem
                            onClick={() => {
                                setSelectedContinent(t);
                            }}
                            key={t.continent.name}
                        >
                            <React.Fragment>{t.continent.name}</React.Fragment>
                        </ContinentFilterItem>
                    );
                })}
            </ContinentFilterList>
        </FilterContainer>
    );
};

export default SearchResultsContinentFilter;

const FilterContainer = styled(SegmentContainer)`
    margin: 15px 0px 15px 30px;
    background: none;
    padding: 0px;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
`;

const ContinentFilterTitle = styled.span`
    color: #eed923;
    font-size: 1.2rem;
    width: 155px;
    padding-top: 4px;
`;

const ContinentFilterList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

const ContinentFilterItem = styled.span`
    border-radius: 6px;
    margin: 2px 4px;
    padding: 1px 5px 3px 5px;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    border: 1px solid #eed923;
    background: #191a1a;
    color: white;

    :hover {
        background: #636767;
    }
`;
