import { LatLng } from 'leaflet';
import React, { useEffect, useState, useCallback } from 'react';
import Autocomplete from 'react-autosuggest';
import styled from 'styled-components';
import { AutocompleteDTO } from '../../api/model';
import Analytics from '../../lib/user-analytics';
import './autocomplete-search-box.css';
import {
    actionClearActiveSearch,
    actionUpdateHighlightedSearchPosition,
    actionClearHighlightedSearch,
} from '../../store/Search/actions';
import ApiAutocomplete from '../../api/api-autocomplete';
import GeoUtil from '../../lib/geo-util';
import { useWindowHeight } from '@react-hook/window-size';
import { useDispatch } from 'react-redux';
import { actionSetHighlightedListingId } from '../../store/Map/MapSelection/actions';

/**
 * Don't use this component directly.  It is responsible for rendering the autocomplete results
 * Use `soar-autocomplete.tsx` instead
 */
interface AutocompleteSearchBoxProps {
    placeholder?: string;
    searchResults: AutocompleteDTO[];
    onSearchResultSelected: (result: AutocompleteDTO) => void;
    onCoordinateSelected: (coordinate: LatLng) => void;
    onFetchRequest: (search: string) => void;
    placeholderAddress?: string;
    isLoading: boolean;
}

const AutocompleteSearchBox = (props: AutocompleteSearchBoxProps) => {
    const dispatch = useDispatch();
    const handleUpdateHighlightedPosition = (position: LatLng) =>
        dispatch(actionUpdateHighlightedSearchPosition(position));
    const handleClearActive = useCallback(() => dispatch(actionClearActiveSearch()), [dispatch]);
    const handleClearHighlighted = useCallback(() => dispatch(actionClearHighlightedSearch()), [dispatch]);
    const handleTileLayerMouseOver = (id: number) => dispatch(actionSetHighlightedListingId(id));
    const handleTileLayerMouseOut = () => dispatch(actionSetHighlightedListingId(undefined));

    const [value, setValue] = useState('');
    const [deviceHeight, setDeviceHeight] = useState<number | undefined>(undefined);

    const height = useWindowHeight();

    const NAVBAR_HEIGHT = 46;

    useEffect(() => {
        if (!deviceHeight) {
            setDeviceHeight(window.innerHeight);
        }
    }, [deviceHeight]);

    useEffect(() => {
        if (props.placeholderAddress) {
            setValue(props.placeholderAddress);
        }
    }, [props.placeholderAddress]);

    const getCoordinateFromSearchTerm = (search: string): LatLng | null => {
        search = search.trim();
        let split;

        //  Separated by a comma
        if (search.includes(',')) {
            split = search
                .replace(' ', '')
                .split(',')
                .filter((word) => word != '');
        }

        //  Separated by white space
        else {
            split = search.split(' ').filter((word) => word != '');
        }

        if (split.length == 2) {
            const lat = parseFloat(split[0]);
            const lng = parseFloat(split[1]);

            if (!(lat > -90 && lat < 90 && lng > -180 && lng < 180)) return null;

            if (lat && lat !== 0 && !isNaN(lat) && lng && lng !== 0 && !isNaN(lng)) {
                setValue(`${lat}, ${lng}`); //  Reformat the search string
                return new LatLng(lat, lng);
            }
        }

        return null;
    };

    useEffect(() => {
        if (!value) {
            handleClearActive();
            handleClearHighlighted();
        }
    }, [value, handleClearActive, handleClearHighlighted]);

    const clearHighlightedSearch = () => {
        handleClearHighlighted();
        handleTileLayerMouseOut();
    };

    return (
        <SearchBox
            height={
                deviceHeight === height ? 'max-content' : `${(deviceHeight || 0) - window.innerHeight - NAVBAR_HEIGHT}px`
            }
            className="autocomplete-search-box"
            style={{ zIndex: 2000 }}
        >
            <Autocomplete
                suggestions={props.searchResults}
                onSuggestionHighlighted={(suggestion) => {
                    if (suggestion && suggestion.suggestion) {
                        if (suggestion.suggestion.type === 'ADDRESS' && suggestion.suggestion.magicKey) {
                            ApiAutocomplete.autocompleteFromMagic(suggestion.suggestion.magicKey)
                                .then((res) => {
                                    if (res.length > 0) {
                                        handleUpdateHighlightedPosition(GeoUtil.latLngFromWKT(res[0].geometryWKT));
                                    }
                                })
                            handleTileLayerMouseOut();
                        } else if (suggestion.suggestion.type === 'LISTING' && suggestion.suggestion.listingId) {
                            handleTileLayerMouseOver(suggestion.suggestion.listingId);
                            handleClearHighlighted();
                        } else {
                            clearHighlightedSearch();
                        }
                    } else {
                        clearHighlightedSearch();
                    }
                }}
                onSuggestionSelected={(event, { suggestion, suggestionValue }) => {
                    clearHighlightedSearch();
                    props.onSearchResultSelected(suggestion);
                    Analytics.Event('Search Bar', `${suggestion.title}`);
                    if (suggestion && !suggestion.title && suggestion.type === 'LISTING' && suggestion.listingId) {
                        setValue(suggestion.listingId.toString());
                        Analytics.Event('Annotation Tool Event', 'Searched for ', suggestion.listingId);
                    } else {
                        setValue(suggestionValue);
                        Analytics.Event('Annotation Tool Event', 'Searched for ', suggestion);
                    }
                }}
                onSuggestionsFetchRequested={({ value }) => {
                    setValue(value);
                    props.onFetchRequest(value);
                }}
                onSuggestionsClearRequested={() => {
                    props.onFetchRequest('');
                    clearHighlightedSearch();
                }}
                getSuggestionValue={(suggestion) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    return suggestion.title || suggestion.listingId!.toString();
                }}
                renderSuggestion={(suggestion) => {
                    return (
                        <React.Fragment>
                            {suggestion.type === 'ADDRESS' ? (
                                <img
                                    className="react-autosuggest-result-icon"
                                    src="/assets/marker-icon-black-outline.svg"
                                />
                            ) : null}

                            {suggestion.type === 'LISTING' ? (
                                <img
                                    className="react-autosuggest-result-icon"
                                    src="/assets/floating-drawer-icons/maps-icon-black.svg"
                                />
                            ) : null}

                            {suggestion.type === 'USER' ? (
                                <img className="react-autosuggest-result-icon" src="/assets/avatars/default_avatar_black.png" />
                            ) : null}

                            <span> {suggestion.title ? suggestion.title : suggestion.listingId}</span>
                        </React.Fragment>
                    );
                }}
                renderInputComponent={(inputProps) => {
                    return (
                        <div className="inputContainer">
                            <i className="fa fa-search react-autosuggest-search-icon" />
                            {/* @ts-ignore */}
                            <input value={value} {...inputProps} />
                            {props.isLoading ? <i className="fa fa-spinner fa-spin react-autosuggest-spinner" /> : null}

                            {!props.isLoading && value ? (
                                <i
                                    className="fa fa-times react-autosuggest-close-search"
                                    onClick={() => {
                                        setValue('');
                                        handleClearActive();
                                        clearHighlightedSearch();
                                    }}
                                />
                            ) : null}
                        </div>
                    );
                }}
                inputProps={{
                    placeholder: props.placeholder ? props.placeholder : 'Search for a location or place...',
                    value,
                    onMouseDown: () => {
                        Analytics.Event('Annotation Tool Event', 'Opened Search Tool');
                    },
                    onChange: (event, { newValue, method }) => {  //eslint-disable-line @typescript-eslint/no-unused-vars
                        setValue(newValue);
                    },
                    onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                            const coordinate = getCoordinateFromSearchTerm(value);
                            if (coordinate) {
                                Analytics.Event('Annotation Tool Event', 'Searched for ', coordinate);
                                Analytics.Event('Search Bar', `${coordinate}`);
                                props.onCoordinateSelected(coordinate);
                            }
                        }
                    },
                }}
            />
        </SearchBox>
    );
};

export default AutocompleteSearchBox;

interface SearchBoxProps {
    height: string;
}

const SearchBox = styled.div<SearchBoxProps>`
    .react-autosuggest__suggestions-container--open {
        overflow-y: auto;

        @media only screen and (max-width: 600px) {
            height: ${(props) => props.height};
        }
    }
`;
