import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const TutorialCarousel = (props) => {
    const { children, show } = props;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [length, setLength] = useState(children.length);

    const [touchPosition, setTouchPosition] = useState(null);

    // Set the length to match current children from props
    useEffect(() => {
        setLength(children.length);
    }, [children]);

    const next = () => {
        if (currentIndex < length - show) {
            setCurrentIndex((prevState) => prevState + 1);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevState) => prevState - 1);
        }
    };

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    };

    const handleTouchMove = (e) => {
        const touchDown = touchPosition;

        if (touchDown === null) {
            return;
        }

        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > 5) {
            next();
        }

        if (diff < -5) {
            prev();
        }

        setTouchPosition(null);
    };

    return (
        <CarouselContainer>
            <div className="carousel-wrapper">
                {/* You can alwas change the content of the button to other things */}
                {currentIndex > 0 && <button onClick={prev} className="left-arrow fa fa-chevron-left" />}
                <div className="carousel-content-wrapper" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div
                        className={`carousel-content show-${show}`}
                        style={{ transform: `translateX(-${currentIndex * (100 / show)}%)` }}
                    >
                        {children}
                    </div>
                </div>
                {/* You can always change the content of the button to other things */}
                {currentIndex < length - show && <button onClick={next} className="right-arrow fa fa-chevron-right" />}
            </div>
        </CarouselContainer>
    );
};

const CarouselContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    .carousel-wrapper {
        display: flex;
        width: 100%;
        position: relative;
    }

    .carousel-content-wrapper {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .carousel-content {
        display: flex;
        transition: all 300ms linear;
        -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
        scrollbar-width: none; /* hide scrollbar in Firefox */
    }

    /* hide scrollbar in webkit browser */
    .carousel-content::-webkit-scrollbar,
    .carousel-content::-webkit-scrollbar {
        display: none;
    }

    .carousel-content > * {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
    }

    .carousel-content.show-2 > * {
        width: 50%;
    }

    .carousel-content.show-3 > * {
        width: calc(100% / 3);
    }

    .carousel-content.show-4 > * {
        width: calc(100% / 4);
    }

    .left-arrow,
    .right-arrow {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        width: 48px;
        height: 48px;
        background-color: transparent;
        border: transparent;
        color: yellow;
        font-size: 30px;
        outline: none;

        .focus {
            outline: none;
        }
    }

    .left-arrow {
        left: -40px;
    }

    .right-arrow {
        right: -40px;
    }

    @media (hover: none) and (pointer: coarse) {
        .left-arrow,
        .right-arrow {
            display: none;
        }
    }
`;
