import { useState } from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';
import MapHelper from '../../lib/map-helper';
import SoarHelper from '../../lib/soar-helper';
import UserHelper from '../../lib/user-helper';
import { selectMyProfile } from '../../store/Account/selectors';
import DrawerHint from '../Drawer/drawer-hint';
import { TransparentButton } from '../Shared/styled-button';

interface UploadMapChooseProps {
    onSelectFile: (file: File) => void;
    handleSetError: (error: string) => void;
    error?: string;
}

const FILE_SIZE_1GB = 1073741824;
const FILE_SIZE_25GB = FILE_SIZE_1GB * 25;

const UploadMapChoose = (props: UploadMapChooseProps) => {
    const [rejectionError, setRejectionError] = useState<string | undefined>(undefined);
    const myProfile = useSelector(selectMyProfile);
    const isReputableUser = UserHelper.isReputableUser(myProfile);

    let MAX_FILE_SIZE: number = FILE_SIZE_1GB;
    if (!SoarHelper.isSoar()) {
        // Subdomain users can upload 25Gb files
        MAX_FILE_SIZE = FILE_SIZE_25GB;
    }

    if (isReputableUser) {
        // Reputable users can upload 25Gb files
        MAX_FILE_SIZE = FILE_SIZE_25GB;
    }

    const handleDrop = (files: File[]) => {
        if (files.length === 0) {
            props.handleSetError('Unsupported file type. Only .TIFF, .ECW, .JP2, .JPG, and .PNG files are accepted');
        } else if (files.length === 1) {
            props.onSelectFile(files[0]);
            setRejectionError(undefined);
        }
    };
    const handleRejectedFile = (files: File[]) => {
        const isFileTooLarge = files.length > 0 && files[0].size >= MAX_FILE_SIZE;
        if (isFileTooLarge) {
            setRejectionError(
                `The map you are trying to upload is too large, maximum map size is ${MapHelper.formatBytes(
                    MAX_FILE_SIZE
                )}.`
            );
            return;
        }

        if (files.length !== 1) {
            setRejectionError('Only one file can be uploaded at a time');
            return;
        }

        const splitFileName = files[0].name.split('.');
        const typeFromName = splitFileName[splitFileName.length - 1];
        const fileType = files[0].type || typeFromName;

        const validFileTypes = ['image/tiff', 'image/tif', 'image/ecw'];

        if (!validFileTypes.includes(fileType)) {
            setRejectionError(`The file type ${fileType && `for ${fileType}`} is not supported`);
            return;
        }
    };

    return (
        <Content>
            {rejectionError || props.error ? (
                <DrawerHint error>{rejectionError ? rejectionError : props.error}</DrawerHint>
            ) : null}
            <Dropzone
                onDropRejected={handleRejectedFile}
                onDrop={handleDrop}
                maxSize={MAX_FILE_SIZE}
                accept=".tiff,.tif,.ecw,.jp2,image/jpeg, image/png"
                style={{
                    margin: '8px',
                    border: '1px dashed rgba(255, 255, 255, 0.6)',
                    borderRadius: '6px',
                    padding: '12px',
                }}
                activeStyle={{
                    border: '1px dashed #EED926',
                }}
            >
                <div className="d-flex justify-content-center mt-2 mb-3">
                    <UploadIcon />
                </div>

                <DropzoneText>Drag and drop your file here</DropzoneText>
                <DropzoneAltText>
                    <i>or</i>
                </DropzoneAltText>
                <div className="d-flex  justify-content-center mt-1 mb-4">
                    <TransparentButton>CHOOSE FILE</TransparentButton>
                </div>
            </Dropzone>

            <DropzoneInstructionText>
                Formats we currently support: GeoTIFF / ECW / JP2 / JPEG / PNG
                <br />
                <LearnMore href="https://about.soar.earth/help.html" target="_blank">
                    Learn more
                </LearnMore>
                <br />
            </DropzoneInstructionText>
        </Content>
    );
};

export default UploadMapChoose;

const Content = styled.div`
    flex: 1 1 auto;
    margin: 0px 2px 0px 2px;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
`;

const UploadIcon = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    width: 150px;
    height: 100px;
    cursor: pointer;
    background-image: url('/assets/upload-icon.svg');
    //background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' enable-background='new 0 0 64 64' version='1.1'%3E%3Cg%3E%3Cg stroke='null' id='svg_1'%3E%3Cpolygon stroke='%23eed926' id='svg_2' points='41,1 9,1 9,63 55,63 55,15 ' stroke-miterlimit='10' stroke-width='2' fill='none'/%3E%3Cpolyline stroke='%23eed926' id='svg_3' points='55,15 41,15 41,1 ' stroke-miterlimit='10' stroke-width='2' fill='none'/%3E%3C/g%3E%3Cpolyline id='svg_4' points='23.998,34 31.998,26 39.998,34 ' stroke-miterlimit='10' stroke-linejoin='bevel' stroke-width='2' stroke='%23eed926' fill='none'/%3E%3Cg id='svg_5'%3E%3Cline id='svg_6' y2='46' x2='31.998' y1='26' x1='31.998' stroke-miterlimit='10' stroke-width='2' stroke='%23eed926' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
`;

const DropzoneText = styled.p`
    color: white;
    text-align: center;
    font-size: 16px;
    margin: 0;
`;

const DropzoneAltText = styled.p`
    color: white;
    text-align: center;
    font-size: 13px;
    margin: 0;
`;

const DropzoneInstructionText = styled.p`
    color: rgba(255, 255, 255, 0.5);
    margin: 12px 8px 8px 8px;
    text-align: left;
`;

const LearnMore = styled.a`
    color: rgba(255, 255, 255, 0.5) !important;
    text-decoration: underline !important;
    cursor: pointer;
`;
