import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { actionFetchUserProfileWithListings } from '../../../store/Map/UserProfile/actions';
import { selectUserListings, selectUserProfile } from '../../../store/Map/UserProfile/selectors';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import { PulseLoader } from '../../Shared/pulse-loader';
import ProfileAllMaps from './profile-all-maps';
import ProfileFeaturedMaps from './profile-featured-maps';
import ProfileHeader from './profile-header';
import ProfileStats from './profile-stats';

type RouteParams = {
    wallet?: string;
};

const ProfileSidebar = () => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const userProfile = useSelector(selectUserProfile);
    const userListings = useSelector(selectUserListings);

    const dispatch = useDispatch();

    const [userProfileError, setUserProfileError] = useState<string | undefined>(undefined);

    const { wallet } = useParams<RouteParams>();

    useEffect(() => {
        if (!wallet) {
            setUserProfileError('Wrong pathname');
        } else {
            dispatch(actionFetchUserProfileWithListings(wallet));
        }
    }, [wallet, dispatch]);

    useEffect(() => {
        if (userProfile) {
            Analytics.Event('Profile', `VP: ${userProfile.userId}`);
            Analytics.Event('Profile', `Viewing user`, userProfile.name);
        }
        return () => {
            if (userProfile) {
                Analytics.Event('Profile', 'Leaving viewing User', userProfile.name);
            }
        };
    }, [userProfile]);

    if (!userProfile || userProfileError) {
        return (
            <ProfileLoadingContainer>
                <PulseLoader iconSize="1.5rem" />
            </ProfileLoadingContainer>
        );
    }

    return (
        <React.Fragment>
            {sideDrawerMode === SideDrawerMode.PROFILE ? (
                <React.Fragment>
                    <ProfileHeader userProfile={userProfile} shareURL={null} />
                    <ProfileStats userProfile={userProfile} listings={userListings.filter((l) => l.listingType)} />
                    {userListings.length > 0 ? (
                        <ProfileFeaturedMaps
                            listings={userListings}
                            profile={userProfile}
                            seeAllMaps={() => {
                                UriHelper.navigateToPath(`/profile/${userProfile.userId}/all-maps`);
                                Analytics.Event('Profile', 'Clicked to see all maps', userProfile.userId);
                            }}
                        />
                    ) : null}
                </React.Fragment>
            ) : null}

            {sideDrawerMode === SideDrawerMode.PROFILE_ALL_MAPS ? <ProfileAllMaps /> : null}
        </React.Fragment>
    );
};

export default ProfileSidebar;

const ProfileLoadingContainer = styled.div`
    margin: 20px;
`;
