// For YouTube video links
export const videoBaseURL = 'https://www.youtube.com/watch?v=';
export const videoListId = '&list=PLI4qxFhFwdkZ-t41ca5NcfNFSQF1knbui';

// For Youtube Image thumbnail
export const imageBaseURL = 'https://img.youtube.com/vi/';
export const imageMaxRes = '/maxresdefault.jpg';

// Tutorial Video Details
export const videoList = [
    { videoId: `q4qgrsm5P_k`, title: 'Register An Account' },
    { videoId: `0Qn5Nb87c54`, title: 'Use The Explore Tab' },
    { videoId: `ADadZD-fKlk`, title: 'View, Download & Share Imagery' },
    { videoId: `vCY1jSx3_vY`, title: 'Upload Maps & Satellite Imagery' },
    { videoId: `FH6jVfZKhyk`, title: 'Ordering New Skymap50 Imagery' },
    { videoId: `a-tgcNo7aBc`, title: 'Ordering Archival Skymap50 Imagery' },
    { videoId: `Rkgq2bm8VgQ`, title: 'Find Drone Imagery' },
    { videoId: `SggZakj7EAk`, title: 'Upload Drone Imagery' },
];
