import styled from 'styled-components';
import { StyledButton } from '../../Shared/styled-button';

interface NotificationRequestLoginProps {
    setShowLogin: () => void;
}

export const NotificationRequestLogin = ({ setShowLogin }: NotificationRequestLoginProps) => {
    return (
        <Container>
            <Row>
                <Icon src="/assets/notifications/login_notification_icon.png" />
                <Title>Looks like you need to login or register to view notifications.</Title>
                <Button onClick={setShowLogin}>LOGIN</Button>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    color: rgb(255 255 255 / 67%);
    background-color: transparent;
    margin: 5px;
    text-align: center;
    padding: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Icon = styled.img`
    margin: 5px 0px 0px 5px;
    width: 34px;
    height: 35px;
    align-items: center;
`;

const Title = styled.div`
    color: white;
    width: 200px;
    margin: 7px 0 7px 12px;
    line-height: 1.2;
    display: flex;
    text-align: left;
    justify-content: center;
    align-items: center;
`;

const Button = styled(StyledButton)`
    margin: 10px auto;
    right: 20px;
    padding: 3px 20px;
    position: absolute;
    align-items: center;
`;
