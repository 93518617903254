import styled from 'styled-components';
import { Input } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import Analytics from '../../lib/user-analytics';
import {
    actionSetDroneImageDistortionTool,
    actionSetDroneImageDistortionOpacity,
} from '../../store/Map/Uploads/actions';
import {
    selectDroneImageDistortionCorners,
    selectDroneImageDistortionTool,
    selectDroneImageOpacity,
} from '../../store/Map/Uploads/selectors';
import DrawerHint from '../Drawer/drawer-hint';
import { StyledTransButton, StyledButton } from '../Shared/styled-button';

interface UploadMapImageLocationProps {
    onClickBack: () => void;
    onSubmit: (corners: number[][] | undefined) => void;
}

const UploadMapImageLocation = (props: UploadMapImageLocationProps) => {
    const corners = useSelector(selectDroneImageDistortionCorners);
    const distortionMode = useSelector(selectDroneImageDistortionTool);
    const opacity = useSelector(selectDroneImageOpacity);

    const dispatch = useDispatch();
    const setDistortionTool = (mode: 'none' | 'rotate' | 'translate' | 'distort' | 'scale') =>
        dispatch(actionSetDroneImageDistortionTool(mode));
    const setDroneOpacity = (opacity: number) => dispatch(actionSetDroneImageDistortionOpacity(opacity));

    return (
        <Container>
            <Content className="d-flex justify-content-between">
                <Title>Choose Location</Title>
            </Content>

            <DrawerHint>
                We were able to set the approximate position of your image. However, it may not be 100% accurate. Use
                these tools to fine-tune its position.
            </DrawerHint>

            <Content className="mt-4">
                <ButtonContainer>
                    <Button
                        active={distortionMode === 'translate'}
                        onClick={() => {
                            distortionMode === 'translate' ? setDistortionTool('none') : setDistortionTool('translate');
                            Analytics.Event('Upload', 'Distortion tools', 'translate');
                        }}
                    >
                        <Icon className="fa fa-arrows-alt" />
                        Move
                    </Button>

                    <Button
                        active={distortionMode === 'scale'}
                        onClick={() => {
                            distortionMode === 'scale' ? setDistortionTool('none') : setDistortionTool('scale');
                            Analytics.Event('Upload', 'Distortion tools', 'scale');
                        }}
                    >
                        <Icon className="fa fa-compress-arrows-alt" />
                        Scale
                    </Button>

                    <Button
                        active={distortionMode === 'rotate'}
                        onClick={() => {
                            distortionMode === 'rotate' ? setDistortionTool('none') : setDistortionTool('rotate');
                            Analytics.Event('Upload', 'Distortion tools', 'rotate');
                        }}
                    >
                        <Icon className="fa fa-sync-alt" />
                        Rotate
                    </Button>

                    <Button
                        active={distortionMode === 'distort'}
                        style={{ minWidth: '108px' }}
                        onClick={() => {
                            distortionMode === 'distort' ? setDistortionTool('none') : setDistortionTool('distort');
                            Analytics.Event('Upload', 'Distortion tools', 'distort');
                        }}
                    >
                        <Icon className="fa fa-vector-square" />
                        Adjust Corners
                    </Button>
                </ButtonContainer>

                <SliderContainer>
                    <SliderText>Transparency:</SliderText>
                    <SliderValue>{(opacity * 100).toFixed()}%</SliderValue>

                    <SliderInputContainer>
                        <Slider
                            type="range"
                            defaultValue={100}
                            value={opacity * 100}
                            step={0.01}
                            onChange={(e) => {
                                const opacity = parseInt(e.target.value) / 100;
                                setDroneOpacity(opacity);
                            }}
                        />
                    </SliderInputContainer>
                </SliderContainer>

                <div className="d-flex mt-4 justify-content-between">
                    <StyledTransButton
                        onClick={() => {
                            props.onClickBack();
                            Analytics.Event('Upload', 'Clicked back arrow at choose location');
                        }}
                    >
                        <i className="fa fa-chevron-left mr-2"></i>BACK
                    </StyledTransButton>

                    <StyledButton
                        className="mr-0 pl-4 pr-4"
                        onClick={() => {
                            setDistortionTool('none');
                            props.onSubmit(corners);
                            Analytics.Event('Upload', 'Clicked continue from choose location');
                        }}
                    >
                        CONTINUE
                        <i className="fa fa-chevron-right ml-2"></i>
                    </StyledButton>
                </div>
            </Content>
        </Container>
    );
};

export default UploadMapImageLocation;

const Container = styled.div`
    margin: 5px 5px 15px 5px;
`;

const Title = styled.h3`
    flex: 0 1 auto;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
`;

const Content = styled.div`
    flex: 1 1 auto;
    margin-right: 0;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Button = styled.button<{ active: boolean }>`
    flex: 1;
    margin: 0 6px 0 6px;
    padding: 8px 1px 8px 1px;
    display: block;
    font-size: 1rem;
    border: ${(props) => (props.active ? '1px solid rgba(0, 0, 0, 1.0);' : '1px solid rgba(0, 0, 0, 0.2);')};
    color: black;
    border-radius: 4px;
    background-color: gray;
    text-transform: uppercase;
    letter-spacing: -0.5px;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
    }

    :focus {
        outline: none;
    }
`;

const Icon = styled.i`
    display: block;
    font-size: 1.1rem;
`;

const SliderContainer = styled.div`
    width: 100%;
    margin: 12px 0 0 0;
    padding: 12px;
    display: flex;
`;

const SliderText = styled.p`
    vertical-align: center;
    color: white;
    font-size: 16px;
    margin: 0;
    padding: 0;
`;

const SliderValue = styled.p`
    vertical-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    min-width: 26px;
    margin: 0;
    padding: 0;
    margin-left: 8px;
`;

const SliderInputContainer = styled.div`
    vertical-align: center;
    margin-left: 20px;
    width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled<any>(Input)`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    -webkit-appearance: none;
    outline: none;
    border-radius: 5px;
    margin-top: 12px;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
    }

    &:focus {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;
