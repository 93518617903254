import { ListingDTO } from '../../../api/model';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface StoreAnimatedLayer {
    animatedLayers: ListingDTO[];
    activeAnimatedLayer: ListingDTO | undefined;
}

const initialState: StoreAnimatedLayer = {
    animatedLayers: [],
    activeAnimatedLayer: undefined,
};

export default (state: StoreAnimatedLayer = initialState, action: Action): StoreAnimatedLayer => {
    switch (action.type) {
        case at.ANIMATED_LAYER_SET_ACTIVE:
            return {
                ...state,
                activeAnimatedLayer: action.payload,
            };
        case at.ANIMATED_LAYERS:
            return {
                ...state,
                animatedLayers: action.payload,
            };
        default:
            return state;
    }
};
