import { actionTypes as at } from './constants';
import { ListingDTO } from '../../../api/model';
import SoarHelper from '../../../lib/soar-helper';
import ApiListings from '../../../api/api-listings';

export const actionFetchSubdomainTileLayer = () => {
    return async (dispatch, getState) => {
        //temp hack to avoid fetching full list of the maps for soar as it is causing perforamance problems
        if (SoarHelper.isSoar()) return;
        dispatch({
            type: at.SUBDOMAIN_FETCH_TILE_LAYER,
        });
        try {
            const subdomainTileLayers = await ApiListings.getSubdomainTileLayers();
            dispatch(actionFetchSubdomainTileLayerSuccess(subdomainTileLayers));
        } catch (error) {
            console.log(error); // TODO: Error state
        }
    };
};

export const actionFetchSubdomainTileLayerSuccess = (tileLayers: ListingDTO[]) => {
    return {
        type: at.SUBDOMAIN_FETCH_TILE_LAYER_SUCCESS,
        payload: tileLayers,
    };
};

export const actionSetSubdomainTileLayerActive = (tileLayer: ListingDTO) => {
    return {
        type: at.SUBDOMAIN_TILE_LAYER_SET_ACTIVE,
        payload: tileLayer,
    };
};

export const actionSetSubdomainTileLayerInactive = () => {
    return {
        type: at.SUBDOMAIN_TILE_LAYER_SET_INACTIVE,
    };
};
