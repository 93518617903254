import React from 'react';
import { Container } from 'reactstrap';

import styled from 'styled-components';

const CGSTLArchiveTermsAndConditions = () => {
    return (
        <CGSTLArchiveTermsAndConditionsContainer>
            <h1>CG Satellite Imagery Terms & Conditions</h1>
            <Container className="privacy-agreement">
                <div className="main main-raised">
                    <div className="section" style={{ marginTop: '-25px' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <br />
                                <div className="col-md-12">
                                    <h5 className="privacy-description">Last updated: 15 June 2022</h5>
                                    <br />

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        Order Specifications
                                    </h5>
                                    <p> All orders, unless otherwise specified will be delivered as:</p>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>50/80/100 cm pixel resolution </li>
                                        <li>WGS84 Datum and UTM projection</li>
                                        <li>Natural colour composite (NCC) image</li>
                                        <li>a GeoTIFF or ECW image format </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        New Collection
                                    </h5>
                                    <p>

                                        In addition to the above, New Collect orders are supplied on the following
                                        terms:
                                    </p>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>Collection will commence within 5 working days of the order</li>
                                        <li>Maximum of 15% cloud cover</li>
                                        <li>
                                            Orders above 15% cloud cover will be reattempted but this will cause a delay
                                            in delivery in worst case scenarios these delay may be months.
                                        </li>
                                        <li>
                                            Delivery of your order will be 2 working days after successful collection.
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        Archive Orders
                                    </h5>
                                    <p>

                                        In addition to the above, Archive orders are supplied on the following terms:
                                    </p>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            All images are ‘As-Is’ with no minimum cloud cover. All preview images
                                            demonstrate the cloud coverage of the image.
                                        </li>
                                        <li>
                                            All orders covering more than 1 scene will be mosaiced to the best of
                                            endeavours.
                                        </li>
                                        <li>
                                            Archive imagery is not geo- or orthorectified and may contain locational
                                            accuracy errors.
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        1. ACCEPTANCE OF TERMS
                                    </h5>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            1.1. The user ("You") agree to and are bound by the Terms of Service set out
                                            below and in any modified or additional terms that Soar.Earth Ltd and any
                                            related entity (together "Soar.Earth") may publish from time to time
                                            (collectively, the "Terms").
                                        </li>
                                        <li>
                                            1.2. Soar.Earth may change these Terms from time to time. Your continued access or
                                            use of the Site constitutes your acceptance of such changes.
                                        </li>
                                        <li>
                                            1.3. Your access and use of the Site is subject to the current version of
                                            the Terms, rules and guidelines posted on the Site at the time of such use.
                                        </li>
                                        <li>
                                            1.4. Your access and use of any imagery purchased through Soar and supplied
                                            by through this Service is subject to the current version of the
                                            Terms,EULA,rules and guidelines posted on the Site at the time of such use.
                                        </li>
                                        <li>
                                            1.5. If you do not agree to these Terms, do not access or use the satellite
                                            imagery from this site.
                                        </li>
                                        <li>
                                            1.6. Submitting an order through the Service means you agree to be bound by
                                            the Soar general Terms of Service and these CG Satellite Imagery Terms
                                            and Conditions.
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        2. DEFINITIONS
                                    </h5>
                                    <table className="privacy-table">
                                        <tr>
                                            <td>Archive Imagery</td>
                                            <td>
                                                Imagery previously collected and stored by the CG Stallite satellite prior
                                                to the placement of an order
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Business day</td>
                                            <td>Refers to Monday to Friday between 9 am and 5 pm GMT+8</td>
                                        </tr>
                                        <tr>
                                            <td>Custom order</td>
                                            <td>
                                                <span>An order that is:</span>
                                                <ul>
                                                    <li>
                                                        An area greater than 300 km<sup>2</sup></li>
                                                    <li>Multispectral imagery</li>
                                                    <li>Stereo imagery</li>
                                                    <li>Raw Imagery; or</li>
                                                    <li>
                                                        Has other requirements that are not capable of being processed
                                                        on the Soar.Earth platform directly.
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Derivative Image Product (DIP)</td>
                                            <td>
                                                means a product or information derived from the Products, but it does
                                                not contain any imagery data derived from the Products, and is
                                                irreversible and uncoupled from the source imagery data derived from the
                                                Products. Notwithstanding the foregoing, for the sake of clarity, any
                                                Digital Elevation Model("DEM") or Digital Terrain Model ("DTM") (in any
                                                form whatsoever, i.e. database for instance) derived from the Products
                                                shall never be considered as a DIP.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>End User</td>
                                            <td>
                                                any individual, legal entity or governmental agency receiving the
                                                Product and accepting these Terms & Conditions. "End User" specifies the
                                                individual, entity or agency with its address that the Product is
                                                delivered, it may be the branches or business entities or offices of the
                                                said individual, entity or agency only when there is a prior written
                                                agreement between You and Soar.
                                            </td>
                                            <tr>
                                                <td>End User License Agreement</td>
                                                <td>
                                                    The agreement between you and CG Stallite ragrding the ownsership, use and license granted for the use of any CG Satellite Product.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>New Collect imagery</td>
                                                <td>
                                                    Imagery from data collection over an area specified by You and is not
                                                    Archive Imagery
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Product</td>
                                                <td>
                                                    Means any New Collect or Archive Imagery supplied by Soar.Earth originating from CG Satellite and covered by the EULA
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>CGEXP</td>
                                                <td>
                                                    Means an Urgent New Collect order request with a 24-hour turn around
                                                    from order acknowledgement by Soar.
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Value Added Product (VAP)</td>
                                                <td>
                                                    means any product developed by You from the Products through technical
                                                    manipulation and /or addition of other data, and resulting in a
                                                    significant modification of the corresponding product. Notwithstanding
                                                    the foregoing, any DEM or DTM derived from the Products is regarded as a
                                                    VAP.
                                                </td>
                                            </tr>
                                        </tr>
                                    </table>
                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        3. CG SSATELLITE IMAGERY ORDERS AND SPECIFICATIONS
                                    </h5>

                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            3.1. Any imagery is supplied under, and the use is limited by the CG Satellite End User
                                            License Agreement.
                                        </li>
                                        <li>
                                            3.2. All imagery is subject to minimum and maximum order size for the Area
                                            of Interest (AOI) for the order and these are:
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                3.2.1 For New Collect orders the minimum area for collection is 50 km
                                                <sup>2</sup>.
                                            </li>
                                            <li>
                                                3.2.2 For New Collect Imagery the minimum area for collection is 10 km
                                                <sup>2</sup> but an additional cost will be incurred for images between 10 and 25 km
                                                <sup>2</sup>.
                                            </li>
                                            <li>
                                                3.2.3 All order have a maximum AOI of 300 km
                                                <sup>2</sup>
                                            </li>
                                            <li>3.2.4 All orders must have a minimum length or width of 3 km.</li>
                                        </ul>
                                        <li>
                                            3.3. New Collect and Archive imagery will be delivered as natural colour
                                            composite (NCC) pan sharpened images in WGS84 datum and UTM projection in either GeoTIFF
                                            or ECW format
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                3.3.1 Where imagery contains more than one scene, these multiple scenes
                                                will be mosaiced to the best of endeavours.
                                            </li>
                                        </ul>
                                        <li>
                                            3.4 All New Collect orders will be delivered with less than 15% cloud cover.
                                            For the purposes of these Terms, cloud cover includes haze, shadows and
                                            smoke. If the New collection exceeds this requirement, the capture will be
                                            reflown at no additional cost and this may take additional time (see Clause
                                            4 below.)
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>

                                                3.4.1 If an area of the image that is covered is considered important or
                                                critical to You, that image is in specification regarding cloud cover
                                                and all imagery is supplied “as-is”. No refund will be considered if the
                                                supplied image is within these specifications.
                                            </li>
                                            <li>
                                                3.4.2 • Orders processed under CGEXP service for urgent New Collect
                                                has no minimum cloud cover and will not be recollected. You accept the
                                                risk of urgent tasking and the risk of excess cloud or obscuration of
                                                areas of specific interest to You.
                                            </li>
                                            <li> 3.5 For Archive imagery:</li>
                                            <ul style={{ listStyle: 'none' }}>
                                                <li>
                                                    3.5.1 Image previews are an indication of final cloud cover and all
                                                    images supplied from Archive as “as-is”. Please ensure that archive
                                                    scenes are chosen carefully to minimise the impact of cloud cover on
                                                    your image.
                                                </li>
                                                <li>

                                                    Imagery is not geo or ortho rectified and may contain locational
                                                    accuracy errors.
                                                </li>
                                            </ul>
                                            <li>
                                                3.6. Where orders are required that cannot be processed directly through
                                                the Service they must be processed manually by Soar (“Custom Order”).
                                                Any request for Custom Orders will be discussed directly with you.
                                                Custom Order may attract a surcharge.
                                            </li>
                                            <li>
                                                3.6. All orders are dependent on acceptance by the satellite provider
                                                and if an order is rejected by the Satellite Provider you will be
                                                informed via email.
                                            </li>
                                        </ul>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        4. COLLECTION AND DELIVERY TIMES
                                    </h5>

                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            4.1. Initial collection attempts of any New Collect order will occur within
                                            5 business days of placement of your order.
                                        </li>
                                        <li>
                                            4.2. CGEXP collect and of a New Collect order within 24 hours of receipt
                                            of your order on any Business Day except Friday.
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                4.2.1 A CGEXP order placed outside the working hours of a Business
                                                Day will be processed the next available business day.
                                            </li>
                                            <li>
                                                4.2.2 A CGEXP order placed on a Friday will be delivered the
                                                following Monday.
                                            </li>
                                        </ul>
                                        <li>
                                            4.3. If Collection fails to meet the cloud content requirements (See Clause
                                            3.3 above) recollection will be attempted at no additional cost. This may
                                            delay collection and depending on local cloud conditions may take up to
                                            several months.
                                        </li>
                                        <li>
                                            4.4. Orders for New Collect imagery will be delivered within 2 working days
                                            of collection.
                                        </li>
                                        <li>
                                            4.5. Orders for Archive imagery will typically be delivered within 2-5
                                            working days of order placement depending on size but sometimes may take
                                            longer.
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        5. PURCHASING IMAGERY AND PAYMENTS
                                    </h5>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            5.1. All purchases will be paid for at the time of ordering and when you
                                            place your order, we will take an authorisation on your credit card. Once
                                            you order has been confirmed as being accepted by Soar.Earth and CG Satellite
                                            , Your credit card will be charged for the full amount.
                                        </li>
                                        <li>
                                            5.2. Where an order is declined by CG Satellite as being unable to
                                            be collected, Your credit card authorisation will be cancelled.
                                        </li>
                                        <li>
                                            5.3. You will be issued an invoice for your purchase which will be sent to
                                            your nominated email address.
                                        </li>
                                    </ul>
                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        6. IMAGE DELIVERY
                                    </h5>

                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            6.1. All images purchased through this service will be available for
                                            download from your user account management panel under the ‘My Satellite
                                            Queue’ Tab on the Soar.Earth Platform.
                                        </li>
                                        <li>
                                            6.2. You may download any images that you have purchased for use in
                                            accordance with the End User License Agreement.
                                        </li>
                                    </ul>

                                    <h5 className="privacy-description" style={{ fontWeight: 'bold' }}>
                                        7. EXCLUDED FROM ORDERING OR RECEIVING DATA
                                    </h5>

                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            7.1. You may not order or receive satellite imagery if You or a person on
                                            whose behalf You are making the purchase are:
                                        </li>
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>
                                                7.1.1. from any country where such distribution or use would be contrary
                                                to law or regulation or which would subject Soar to any registration
                                                requirement within such jurisdiction or country; or
                                            </li>
                                            <li>
                                                7.1.2.from (or to a national or resident of) any country to which
                                                Australia has embargoed goods; or
                                            </li>
                                            <li>
                                                7.1.3. on the U.S. Treasury Department's list of Specially Designated
                                                Nationals or the U.S. Commerce Department's Table of Deny Orders; or
                                            </li>
                                            <li>
                                                7.1.4. a person, including government, state or organisation, who is
                                                listed as under sanction through the authority of:
                                            </li>
                                            <ul style={{ listStyle: 'none' }}>
                                                <li>7.1.4.1. the United Nations Charter Act 1945 (Cth);</li>
                                                <li>7.1.4.2. the Autonomous Sanctions Act 2011 (Cth) </li>
                                                <li>7.1.4.3. the US OFAC consolidated list; or</li>
                                                <li>
                                                    7.1.4.4. any other statute or regulation which would make supply of
                                                    any Product illegal.
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </CGSTLArchiveTermsAndConditionsContainer>
    );
};

export default CGSTLArchiveTermsAndConditions;


const CGSTLArchiveTermsAndConditionsContainer = styled.div`
    padding-top: 100px;
    color: white !important;
    -webkit-text-fill-color: white;
    text-align: left !important;

    h1 {
        margin: 10px 75px;
        font-size: 1.75rem;
    }

    h5 {
        color: rgb(238, 217, 38) !important;
        -webkit-text-fill-color: rgb(238, 217, 38);
        text-align: left !important;

        :first-child {
            color: rgba(255, 255, 255, 0.8);
            -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
            font-size: 14px;
            text-align: center !important;
        }
    }

    li,
    ul {
        color: white !important;
        -webkit-text-fill-color: white;
        font-size: 16px !important;
        font-size: 1rem;
    }

    .privacy-table {
        margin: 10px 0px;
        tr > td {
            :first-child {
                color: rgb(238, 217, 38) !important;
                -webkit-text-fill-color: rgb(238, 217, 38) !important;
                vertical-align: top;
                width: 170px;
            }
        }
    }
`;