import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';
import { ListingDictionary, ListingGroup } from './model';

interface MapSelectionStore {
    listingMyMapsDictionaryIsLoading: boolean;
    listingMyMapsDictionary: ListingDictionary | undefined;

    visibleListingMyMapGroups: ListingGroup[];
    highlightedListingMyMapGroup: ListingGroup | undefined;
    highlightedListingMyMapId: number | undefined;
}

const initialState: MapSelectionStore = {
    listingMyMapsDictionaryIsLoading: false,
    listingMyMapsDictionary: undefined,
    visibleListingMyMapGroups: [],
    highlightedListingMyMapGroup: undefined,
    highlightedListingMyMapId: undefined,
};

export default (state: MapSelectionStore = initialState, action: Action): MapSelectionStore => {
    switch (action.type) {
        case at.FETCH_MY_MAPS_TILE_LAYER_DICTIONARY:
            return {
                ...state,
            };

        case at.FETCH_MY_MAPS_TILE_LAYER_DICTIONARY_LOADING:
            return {
                ...state,
                listingMyMapsDictionaryIsLoading: action.payload,
            };

        case at.FETCH_MY_MAPS_TILE_LAYER_DICTIONARY_SUCCESS:
            return {
                ...state,
                listingMyMapsDictionary: action.payload,
            };

        case at.SET_MY_MAPS_VISIBLE_TILE_LAYER_GROUPS:
            return {
                ...state,
                visibleListingMyMapGroups: action.payload,
            };

        case at.SET_MY_MAPS_HIGHLIGHTED_TILE_LAYER_GROUP:
            return {
                ...state,
                highlightedListingMyMapGroup: action.payload,
            };

        case at.SET_MY_MAPS_HIGHLIGHTED_TILE_LAYER_ID:
            return {
                ...state,
                highlightedListingMyMapId: action.payload,
            };

        default:
            return state;
    }
};
