import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';
import { imageBaseURL, imageMaxRes, videoBaseURL, videoListId } from './tutorial-options';

interface TutorialCarouselItemProps {
    videoId: string;
    altText: string;
}

export const TutorialCarouselItem = ({ videoId, altText }: TutorialCarouselItemProps) => {
    const srcLink = `${imageBaseURL}${videoId}${imageMaxRes}`;
    const videoSrcLink = `${videoBaseURL}${videoId}${videoListId}`;

    const handleOpenExternalTutorial = () => {
        window.open(videoSrcLink, '_blank');
        Analytics.Event('Tutorial', 'Clicked to open carousel video', videoSrcLink);
    };

    return (
        <CarouselItem>
            <img src={srcLink} alt={altText} className="carousel-preview" onClick={handleOpenExternalTutorial} />
        </CarouselItem>
    );
};

const CarouselItem = styled.div`
    padding: 4px;

    .carousel-preview {
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        width: 100%;
        min-width: 100px;
        cursor: pointer;

        &:hover {
            border: 1px solid #eed926;
            -webkit-box-shadow: 0px 0px 5px 0px rgb(238 227 8 / 49%);
            -moz-box-shadow: 0px 0px 5px 0px rgb(238 227 8 / 49%);
            box-shadow: 0px 0px 5px 0px rgb(238 227 8 / 49%);
        }
    }
`;
