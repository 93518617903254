import { useState } from 'react';
import styled from 'styled-components';
import Analytics, { ConversionEvent } from '../../../../lib/user-analytics';
import { Button, Content, Divider, Title } from '../satellite-drawer-styles';

interface SatelliteOrderConditionsProps {
    termsAndConditions: string[];
    termsAndConditionsHref: string;
    handleSubmitConditions: () => void;
    isLoading?: boolean;
}

export const SKYMAP50_NEW_COLLECTION_TERMS = [
    'I accept that collection will have a maximum of 15% cloud haze or smoke and above this may delay delivery of the order',
    'I accept that this 15% minimum cloud coverage may obstruct an area I consider critical',
    'I accept that Mosaicing may be required and that Soar will make ‘best endeavours’ to do this',
    'I accept that Collection will commence within 5 working days of order placement',
];

export const SKYMAP50_ARCHIVE_COLLECTION_TERMS = [
    'I accept that images are supplied ‘as is’ with cloud / smoke or haze as shown on the preview',
    'I accept that images are not ortho / georectified and some locational errors may occur',
    'I accept that my order may be mosaiced and that Soar will make ‘best endeavours to do this’',
    'I accept that orders may take up to 5 working days to deliver depending on size and complexity',
];

export const CGSTL_ARCHIVE_COLLECTION_TERMS = [
    'I accept that images are supplied ‘as is’ with cloud / smoke or haze as shown on the preview',
    'I accept that images are not ortho / georectified and some locational errors may occur',
    'I accept that my order may be mosaiced and that Soar will make ‘best endeavours to do this’',
    'I accept that orders may take up to 5 working days to deliver depending on size and complexity',
];

const SatelliteOrderConditions = (props: SatelliteOrderConditionsProps) => {
    const [termsChecked, setTermsChecked] = useState(false);

    const handleSubmit = () => {
        if (termsChecked) {
            props.handleSubmitConditions();
            Analytics.Conversion(ConversionEvent.ACCEPT_TERMS_AND_CONDITIONS);
            Analytics.Event('Satellite', 'Accepted tnc');
        }
    };

    return (
        <Content>
            <Title>Terms and Conditions</Title>

            <ConditionsContainer>
                <Checklist>
                    {props.termsAndConditions.map((terms, index) => {
                        return <ChecklistItem key={index.toString()}>{terms}</ChecklistItem>;
                    })}

                    <ChecklistItem>
                        I agree to the&nbsp;
                        <TermsAndConditionsLink href={props.termsAndConditionsHref} target="_blank">
                            Terms and Conditions
                        </TermsAndConditionsLink>
                    </ChecklistItem>
                </Checklist>

                <Checkbox
                    name="checked-accept-all"
                    type="checkbox"
                    checked={termsChecked}
                    onChange={() => {
                        setTermsChecked(!termsChecked);
                    }}
                />

                <CheckboxText>I have read and agree to all the above terms and conditions</CheckboxText>
            </ConditionsContainer>

            <Divider />

            <SubmitButton disabled={!termsChecked || props.isLoading} onClick={handleSubmit}>
                {props.isLoading && <i className="fas fa-spinner fa-spin mr-2"></i>}
                Accept Terms
            </SubmitButton>
        </Content>
    );
};

export default SatelliteOrderConditions;

const ConditionsContainer = styled.div`
    padding: 12px;
    margin-top: 8px;
    color: white;
`;

const Checklist = styled.ul`
    padding-left: 32px;
`;

const ChecklistItem = styled.li`
    color: white;
    margin-bottom: 12px;
`;

const Checkbox = styled.input`
    margin: 12px 8px 0 12px;
    width: 1.1rem;
    height: 1.1rem;
`;

const CheckboxText = styled.span`
    color: white;
    position: relative;
    top: -2px;
`;

const TermsAndConditionsLink = styled.a`
    color: #eed926;
    text-decoration: underline !important;

    :hover {
        color: #eed926;
    }
`;

const SubmitButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 10px;
`;
