import { AppState } from '../root-reducer';

export const selectSideDrawerMode = (state: AppState) => state.sideDrawerDomain.sideDrawerMode;

export const selectSideDrawerOpen = (state: AppState) => state.sideDrawerDomain.sideDrawerOpen;

export const selectCategoryMode = (state: AppState) => state.sideDrawerDomain.categoryMode;

export const selectFeaturedCategory = (state: AppState) => state.sideDrawerDomain.featuredListings;

export const selectFeaturedCategoryLoading = (state: AppState) => state.sideDrawerDomain.isLoadingFeaturedListings;

export const selectSideDrawerCollapse = (state: AppState) => state.sideDrawerDomain.sideDrawerCollapse;
