import Axios from 'axios';
import { LatLng, LatLngBounds } from 'leaflet';

import Constants from '../constants';

const GEOCODE_API_KEY = Constants.GEOCODE_API;
const GEOCODE_API_REVERSE = 'https://app.geocodeapi.io/api/v1/reverse';

export interface GeocoderResult {
    address: string;
    location?: LatLng;
    locationType: string;
    bounds?: LatLngBounds | LatLng;
}

export default class Geocoder {
    public static fromLatLng(latLng: LatLng): Promise<[GeocoderResult]> {
        const url = `${GEOCODE_API_REVERSE}?apikey=${GEOCODE_API_KEY}&point.lat=${latLng.lat}&point.lon=${latLng.lng}`;

        return new Promise<[GeocoderResult]>((resolve, reject) => {
            Axios.get(url)
                .then((result) => {
                    resolve(
                        result.data.features &&
                            result.data.features.map((address) => {
                                let point: LatLng | undefined = undefined;
                                let bounds: LatLngBounds | undefined = undefined;

                                if (address.bbox) {
                                    point = new LatLng(
                                        address.geometry.coordinates[0],
                                        address.geometry.coordinates[1]
                                    );
                                    const northEast = new LatLng(address.bbox[1], address.bbox[0]);
                                    const southWest = new LatLng(address.bbox[3], address.bbox[2]);
                                    bounds = new LatLngBounds(northEast, southWest);
                                } else if (address.geometry.type === 'Point') {
                                    point = new LatLng(
                                        address.geometry.coordinates[1],
                                        address.geometry.coordinates[0]
                                    );
                                }

                                const geocoderResult: GeocoderResult = {
                                    address: address.properties.label,
                                    location: point,
                                    locationType: address.type,
                                    bounds: bounds,
                                };

                                return geocoderResult;
                            })
                    );
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
