import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';
import SearchContentCard from './search-content-card';

export interface FixedMasonaryItem {
    id: number;
    authorName: string;
    title: string;
}

interface SearchContentFixedMasonaryProps {
    items: FixedMasonaryItem[];
    handleSelectListing: (id: number) => void;
}

const SearchContentFixedMasonary = (props: SearchContentFixedMasonaryProps) => {
    const handleOnClick = (id: number) => {
        Analytics.Event('Search Bar', 'Clicked Map From Hottest', id);
        props.handleSelectListing(id);
    };

    return (
        <Container>
            {props.items.length >= 3 && (
                <FirstColumn>
                    <FirstColumnInner>
                        <SearchContentCard
                            height="40%"
                            id={props.items[0].id}
                            title={props.items[0].title}
                            onClick={handleOnClick}
                        />
                        <SearchContentCard
                            height="40%"
                            id={props.items[1].id}
                            title={props.items[1].title}
                            onClick={handleOnClick}
                        />
                        <FlexColumnBreak />
                        <SearchContentCard
                            height="60%"
                            id={props.items[2].id}
                            title={props.items[2].title}
                            onClick={handleOnClick}
                        />
                    </FirstColumnInner>
                </FirstColumn>
            )}
            {props.items.length >= 4 && (
                <SecondColumn>
                    <SearchContentCard
                        height="100%"
                        id={props.items[3].id}
                        title={props.items[3].title}
                        onClick={handleOnClick}
                    />
                </SecondColumn>
            )}
            {props.items.length >= 6 && (
                <ThirdColumn>
                    <SearchContentCard
                        height="50%"
                        id={props.items[4].id}
                        title={props.items[4].title}
                        onClick={handleOnClick}
                    />
                    <SearchContentCard
                        height="50%"
                        id={props.items[5].id}
                        title={props.items[5].title}
                        onClick={handleOnClick}
                    />
                </ThirdColumn>
            )}
        </Container>
    );
};

export default SearchContentFixedMasonary;

const Container = styled.div`
    display: flex;
    height: 400px;
`;

const FirstColumn = styled.div`
    flex: 5;
    display: flex;
    width: 100%;
    height: 400px;
`;

const FirstColumnInner = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

const SecondColumn = styled.div`
    flex: 5;
    height: 400px;
`;

const ThirdColumn = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    height: 400px;
`;

const FlexColumnBreak = styled.div`
    flex-basis: 100%;
    width: 0;
    height: 0;
`;
