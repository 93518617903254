import { ListingDTO } from '../../api/model';
import SoarHelper from '../../lib/soar-helper';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';
import { SideDrawerMode } from './model';

interface StoreSideDrawer {
    sideDrawerMode: SideDrawerMode;
    sideDrawerOpen: boolean;
    categoryMode: string | undefined;
    featuredListings: ListingDTO[] | undefined;
    isLoadingFeaturedListings: boolean;
    sideDrawerCollapse: boolean;
}

const initialState: StoreSideDrawer = {
    sideDrawerMode: SoarHelper.isSoar() ? SideDrawerMode.MAPS : SideDrawerMode.SUBDOMAIN_MAPS,
    sideDrawerOpen: true,
    categoryMode: undefined,
    featuredListings: undefined,
    isLoadingFeaturedListings: false,
    sideDrawerCollapse: false,
};

export default (state: StoreSideDrawer = initialState, action: Action): StoreSideDrawer => {
    switch (action.type) {
        case at.SIDEDRAWER_MODE:
            return {
                ...state,
                sideDrawerMode: action.payload,
                sideDrawerCollapse: false,
            };
        case at.SIDEDRAWER_OPEN:
            return {
                ...state,
                sideDrawerOpen: action.payload,
            };
        case at.CATEGORY_MODE:
            return {
                ...state,
                categoryMode: action.payload,
            };
        case at.FEATURED_CATEGORY_LISTINGS:
            return {
                ...state,
                featuredListings: action.payload,
            };
        case at.FEATURED_CATEGORY_LOADING:
            return {
                ...state,
                isLoadingFeaturedListings: action.payload,
            };
        case at.SIDEDRAWER_COLLAPSE: {
            return {
                ...state,
                sideDrawerCollapse: action.payload,
            };
        }
        default:
            return state;
    }
};
