import { useState } from 'react';
import { Input, InputGroup, InputGroupText, InputGroupAddon, Spinner } from 'reactstrap';
import styled from 'styled-components';
import { SubDomainPriceDTO } from '../../api/model';
import Analytics from '../../lib/user-analytics';
import DrawerHint from '../Drawer/drawer-hint';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../Shared/soar-modal';
import { StyledButton } from '../Shared/styled-button';

interface VoucherInputProps {
    toggle: () => void;
    showCouponInput: boolean;
    reconfigureSubdomainName: () => void;
    handleSubmitCoupon: (coupon: string) => void;
    validatingCoupon: boolean;
    couponError: string | undefined;
    handleCouponError: (error: string) => void;
    validVoucherResponse: boolean;
    voucherDetails?: SubDomainPriceDTO;
}

export const VoucherInputModal = (props: VoucherInputProps) => {
    const [coupon, setCoupon] = useState<string>('');

    const handleCoupon = (e) => {
        e.preventDefault();
        if (coupon) {
            Analytics.Event('Pricing', 'Applied coupon', coupon);
            props.handleSubmitCoupon(coupon);
        } else {
            props.handleCouponError('Please check the voucher');
        }
    };
    return (
        <SoarModal title="Discount Voucher" isOpen={true} toggle={props.toggle} width="400px" marginTop="30vh">
            <ModalBodyContainer>
                <h3>Input your voucher code here</h3>
                <InputGroup>
                    <VoucherInput
                        placeholder="Voucher Code..."
                        value={coupon}
                        onChange={(e) => {
                            setCoupon(e.target.value);
                            props.handleCouponError('');
                        }}
                    />
                    <InputGroupAddon addonType="append">
                        <VoucherInputGroupText>
                            {!props.validatingCoupon ? (
                                <VoucherInputIcon onClick={(e) => handleCoupon(e)} />
                            ) : (
                                <CouponCheckingSpinner />
                            )}
                        </VoucherInputGroupText>
                    </InputGroupAddon>
                </InputGroup>
                {props.couponError && (
                    <VoucherErrorDetails>
                        <DrawerHint error>{props.couponError}</DrawerHint>
                    </VoucherErrorDetails>
                )}
                {props.validVoucherResponse && props.voucherDetails && (
                    <VoucherValidDetails>
                        <h2>Voucher Applied!</h2>
                        <p>
                            {props.voucherDetails.durationInMonths !== 0 &&
                                `You have ${props.voucherDetails.durationInMonths} month/s free`}
                        </p>
                        <p>
                            {props.voucherDetails.totalPrice !== 0 &&
                                `Afterwards it will cost only ${
                                    props.voucherDetails.currency
                                } $${props.voucherDetails.totalPrice.toFixed(2)}/month`}
                        </p>
                    </VoucherValidDetails>
                )}
            </ModalBodyContainer>
            <ModalFooterContainer>
                <VoucherInputButton onClick={(e) => handleCoupon(e)}>APPLY VOUCHER</VoucherInputButton>
            </ModalFooterContainer>
        </SoarModal>
    );
};

const ModalBodyContainer = styled(StyledModalBody)`
    h3 {
        color: white;
        font-size: 24px;
        letter-spacing: 3%;
        margin-bottom: 20px;
        text-align: left;
        color: white;
    }
`;

const ModalFooterContainer = styled(StyledModalFooter)`
    display: flex;
`;

const VoucherErrorDetails = styled.div`
    margin-top: 20px;
    border: 1px solid red;
    color: red;
    border-radius: 6px;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;

    div {
        margin: 0px;
        border: none;
        justify-content: center;
        display: flex;
        align-items: center;

        p {
            margin: 0px 0 0 5px;
            color: red !important;
        }
    }
`;

const VoucherValidDetails = styled.div`
    margin-top: 20px;
    border: 1px solid #eed926;
    border-radius: 6px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;

    p {
        color: white;
        font-size: 15px;
    }

    h2 {
        color: #eed926;
    }
`;

const VoucherInputButton = styled(StyledButton)`
    border-radius: 6px;
    font-size: 16px;
    padding: 4px;
    flex: auto;
`;

const VoucherInput = styled(Input)`
    width: 100%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;

    :focus {
        background-color: transparent;
        color: white;
        border-color: #eed926;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba(238, 218, 38, 0.25);
    }
`;

const VoucherInputGroupText = styled(InputGroupText)`
    background-color: #eed926;
`;

const VoucherInputIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='20' height='20' rx='6' stroke='%23191A1A' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.55469 11.0003L9.99933 14.3337L16.5547 7.66699' stroke='%23191A1A' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const CouponCheckingSpinner = styled(Spinner)`
    color: #eed926;
    font-size: 20px;
`;
