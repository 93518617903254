import { LatLng, LatLngBounds } from 'leaflet';
import GeoUtil from './geo-util';

/**
 * The LatLngBoundsHash is a geohash but for LatLngBounds
 *
 * It's designed to be a computationally cheap way of grouping polygons by both position and size
 *
 * A lat/lng pair for the two opposite corners are padded so it's sign and digits line up eg.
 *   -31.12345678, 115.12345678, -30.12345678, 116.12345678
 *
 * becomes:
 *   -03112345678000, +11512345678000, -03012345678000, +11612345678000
 *
 * Then a character from each pair is selected one at a time until all characters are used.
 * This leaves us with a single string suitable for a dictionary key.  Truncating the length
 * of the hash will increase the tendency for two given LatLngBounds of similar size and position
 * to have a hash collision.
 */
export class LatLngBoundsHash {
    // Approximate `quickAreas` for maps best described at a particular zoom level.  Used for key length culling
    static ZOOM_LEVEL_3 = 59156;
    static ZOOM_LEVEL_4 = 31387.5;
    static ZOOM_LEVEL_5 = 26150;
    static ZOOM_LEVEL_6 = 19996;
    static ZOOM_LEVEL_7 = 18687;
    static ZOOM_LEVEL_8 = 17149;
    static ZOOM_LEVEL_9 = 16674;
    static ZOOM_LEVEL_10 = 16510;
    static ZOOM_LEVEL_11 = 16333;
    static ZOOM_LEVEL_12 = 16257;
    static ZOOM_LEVEL_13 = 16237;
    static ZOOM_LEVEL_14 = 16216;
    static ZOOM_LEVEL_15 = 16206;
    static ZOOM_LEVEL_16 = 16204;
    static ZOOM_LEVEL_17 = 16203;
    static ZOOM_LEVEL_18 = 16202;
    static ZOOM_LEVEL_19 = 16201;
    static ZOOM_LEVEL_20 = 16200;

    hash: string;

    constructor(bounds: LatLngBounds) {
        const [neLatKey, neLngKey] = this.keyForLatLng(bounds.getNorthEast());
        const [swLatKey, swLngKey] = this.keyForLatLng(bounds.getSouthWest());


        const len = neLatKey.length;
        if (len === neLngKey.length && len === swLatKey.length && len === swLngKey.length) {
            let key = '';
            for (let i = 0; i < len; i++) {
                key = key + neLatKey.charAt(i) + neLngKey.charAt(i) + swLatKey.charAt(i) + swLngKey.charAt(i);
            }

            // Only take the first few numbers of significance, effectively creating a
            // fuzzy equality in both position and size
            this.hash = key.substring(0, this.keyLengthForBounds(bounds));
        } else {
            throw new Error('Precondition error: lat, lng and size keys do not match');
        }
    }

    // If you are trying to influence the grouping (clustering) of areal's this is the place.
    // This value dictates how 'fuzzy' the equality of two LatLngBounds are in both size and position
    // Reducing the key length increases the tendency for similar areal's to group together
    // I came to these numbers by trial and error looking at production data
    // Maps that take a large geographical area need to group more readily than small maps
    // TODO:  Establish a mathematical relationship between geographical size and key length
    //        (currently it's very subjective and uses some magic numbers)
    public keyLengthForBounds(bounds: LatLngBounds) {
        const area = GeoUtil.quickArea(bounds);
        if (area > LatLngBoundsHash.ZOOM_LEVEL_3) return 11;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_4) return 11;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_5) return 11;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_6) return 11;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_7) return 11;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_8) return 11;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_9) return 15;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_10) return 15;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_11) return 15;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_12) return 18;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_13) return 18;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_14) return 18;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_15) return 18;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_16) return 18;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_17) return 18;
        if (area > LatLngBoundsHash.ZOOM_LEVEL_18) return 18;
        return 32;
    }

    private keyForLatLng(latLng: LatLng): [string, string] {
        const latSign = latLng.lat > 0 ? '+' : '-';
        const lngSign = latLng.lng > 0 ? '+' : '-';

        const latInteger = this.padIntegerTo3Digits(Math.floor(Math.abs(latLng.lat)));
        const lngInteger = this.padIntegerTo3Digits(Math.floor(Math.abs(latLng.lng)));
        const latDecimal = this.padDecimalTo16Digits(this.removeIntegerFromDecimal(Math.abs(latLng.lat)));
        const lngDecimal = this.padDecimalTo16Digits(this.removeIntegerFromDecimal(Math.abs(latLng.lng)));

        const latString = `${latSign}${latInteger}${latDecimal}`;
        const lngString = `${lngSign}${lngInteger}${lngDecimal}`;

        return [latString, lngString];
    }

    private padIntegerTo3Digits(value: number): string {
        return `000${value}`.slice(-3);
    }

    private padDecimalTo16Digits(value: number): string {
        return `${value}0000000000000000`.substring(0, 16);
    }

    private removeIntegerFromDecimal(value: number): number {
        return parseInt((value % 1).toString().replace('0.', ''));
    }
}
