import { useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import styled from 'styled-components';
import { CSWHistoricalRequestCost } from '../../../../api/model';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../../Shared/soar-modal';
import { StyledButton } from '../../../Shared/styled-button';
import { SubmittingSpinner } from '../../../Shared/submitting-spinner';
import DrawerHint from '../../drawer-hint';

interface VoucherInputProps {
    toggle: () => void;
    handleSubmitCoupon: (coupon: string) => void;
    couponError: string | undefined;
    voucherDetails?: CSWHistoricalRequestCost;
    isLoading: boolean;
    isOpen: boolean;
}

const SatelliteOrderVoucherInput = (props: VoucherInputProps) => {
    const [coupon, setCoupon] = useState<string>('');
    const [couponError, setCouponError] = useState<string | undefined>(undefined);
    const [voucherDetails, setVoucherDetails] = useState<CSWHistoricalRequestCost | undefined>(undefined);

    useEffect(() => {
        setCouponError(undefined);
        setVoucherDetails(undefined);
    }, [coupon]);

    useEffect(() => {
        setCouponError(props.couponError);
        setVoucherDetails(props.voucherDetails);
    }, [props.isLoading, props.couponError, props.voucherDetails]);

    const handleCoupon = (e) => {
        e.preventDefault();

        if (!coupon) {
            setCouponError('Please enter a voucher code');
            return;
        }
        setCouponError(props.couponError);
        setVoucherDetails(props.voucherDetails);
        props.handleSubmitCoupon(coupon);
    };

    const toggle = () => {
        setCoupon('');
        setCouponError(undefined);
        setVoucherDetails(undefined);
        props.toggle();
    };

    return (
        <SoarModal title="Discount Voucher" isOpen={props.isOpen} toggle={toggle} width="400px" marginTop="30vh">
            <ModalBodyContainer>
                <h3>Input your voucher code here</h3>
                <InputGroup>
                    <VoucherInput
                        placeholder="Voucher Code..."
                        value={coupon}
                        onChange={(e) => {
                            setCoupon(e.target.value);
                        }}
                    />
                    <InputGroupAddon addonType="append">
                        <VoucherInputGroupText>
                            <VoucherInputIcon onClick={(e) => handleCoupon(e)} />
                        </VoucherInputGroupText>
                    </InputGroupAddon>
                </InputGroup>

                {props.isLoading ? (
                    <SubmittingSpinner />
                ) : couponError ? (
                    <VoucherErrorDetails>
                        <DrawerHint error>{couponError}</DrawerHint>
                    </VoucherErrorDetails>
                ) : voucherDetails?.voucherValueUsed ? (
                    <VoucherValidDetails>
                        <h2>Voucher Applied!</h2>
                        <h5>
                            {voucherDetails.voucherValueUsed &&
                                `Save ${
                                    voucherDetails.currency === 'USD' ? 'US' : voucherDetails.currency
                                } $${voucherDetails.voucherValueUsed.toFixed(2)}`}
                        </h5>
                        <p>
                            {voucherDetails.totalPrice && voucherDetails.totalPrice !== 0
                                ? `New Price ${
                                      voucherDetails.currency === 'USD' ? 'US' : voucherDetails.currency
                                  } $${voucherDetails.totalPrice.toFixed(2)}`
                                : ''}
                        </p>
                        <h3 className="text-center">{voucherDetails.totalPrice === 0 && `FREE COLLECTION`}</h3>
                    </VoucherValidDetails>
                ) : null}
            </ModalBodyContainer>
            <ModalFooterContainer>
                {!props.isLoading && (
                    <VoucherInputButton onClick={(e) => handleCoupon(e)}>APPLY VOUCHER</VoucherInputButton>
                )}
            </ModalFooterContainer>
        </SoarModal>
    );
};

export default SatelliteOrderVoucherInput;

const ModalBodyContainer = styled(StyledModalBody)`
    h3 {
        color: rgba(255, 255, 255, 0.6);
        font-size: 24px;
        margin-bottom: 20px;
        text-align: left;
    }
`;

const ModalFooterContainer = styled(StyledModalFooter)`
    display: flex;
`;

const VoucherErrorDetails = styled.div`
    margin-top: 20px;
    border: 1px solid red;
    color: red !important;
    border-radius: 6px;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        margin: 0px;
        border: none;
        justify-content: center;
        display: flex;

        p {
            margin: 0px 0 0 5px;
            color: red !important;
            text-align: center;
        }
    }
`;

const VoucherValidDetails = styled.div`
    margin-top: 20px;
    border: 1px solid #eed926;
    border-radius: 6px;
    text-align: center;
    padding-top: 10px;

    p {
        color: white;
        font-size: 18px;
    }

    h2,
    h5 {
        color: #eed926;
    }
`;

const VoucherInputButton = styled(StyledButton)`
    border-radius: 6px;
    font-size: 16px;
    padding: 4px;
    flex: auto;
`;

const VoucherInput = styled(Input)`
    width: 100%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;

    :focus {
        background-color: transparent;
        color: white;
        border-color: #eed926;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba(238, 218, 38, 0.25);
    }
`;

const VoucherInputGroupText = styled(InputGroupText)`
    background-color: #eed926;
`;

const VoucherInputIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='20' height='20' rx='6' stroke='%23191A1A' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.55469 11.0003L9.99933 14.3337L16.5547 7.66699' stroke='%23191A1A' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;
