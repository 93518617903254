import Api from './api';
import { AnalyticsAction, AnalyticsNote, SatelliteProvider } from './model';
import ApiUser from './api-user';

export default class ApiAnalytics extends Api {
    public static postAnalyticsSatellite(
        url: string,
        provider: SatelliteProvider,
        satellite: string,
        action: AnalyticsAction
    ): Promise<void> {
        const data = {
            url: url,
            provider: provider.toString(),
            satellite: satellite,
            action: action,
        };
        return (
            this.axios
                .post('/v1/analytics/satellite', data)
                .then((res) => {
                    return res.data;
                })
                .then((_) => {
                    if (action === AnalyticsAction.VIEW) {
                        ApiUser.updateMyPoints();
                    }
                })
                // we catch and ignore error here
                .catch((err) => console.log(err))
        );
    }

    public static postAnalyticsListing(
        action: AnalyticsAction,
        note: AnalyticsNote,
        listingId: number,
        isTask?: boolean
    ): Promise<void> {
        const data = {
            action: action, // view or download
            note: note, //  description of event
            id: listingId,
            isTask,
        };
        return (
            this.axios
                .post('/v1/analytics/listing', data)
                .then((res) => {
                    return res.data;
                })
                .then((_) => {
                    ApiUser.updateMyPoints();
                })
                // we catch and ignore error here
                .catch((err) => console.log(err))
        );
    }
}
