import DrawerHint from "../drawer-hint";
import { Button, Content, TeaserIcon, TeaserTitle } from "./satellite-drawer-styles";

interface SatelliteDrawerAOIErrorProps {
    title: string;
    icon: string;
    onClick: () => void;
    error: string;
    id?: string
}

export const SatelliteDrawerAOIError = (props: SatelliteDrawerAOIErrorProps) => {
    const { title, icon, onClick, error } = props;
    return (
        <Content>
            <TeaserTitle>{title}</TeaserTitle>
            <TeaserIcon src={icon} />
            <Button onClick={onClick}>
                RESET AREA OF INTEREST
            </Button>
            <DrawerHint error>{error}</DrawerHint>
        </Content>
    )
}
