import React from 'react';
import { ORDER_TYPE } from '../../../store/Map/Tasks/reducer';
import { PulseLoader } from '../../Shared/pulse-loader';
import DrawerHint from '../drawer-hint';
import { StyledDrawerSubTitle, StyledDrawerTitle } from '../Shared/Drawer/drawer-styles';

interface OrderSidebarProps {
    order: ORDER_TYPE;
}

const OrderSidebar = ({ order }: OrderSidebarProps) => {
    if (order === 'FETCHING')
        return (
            <div>
                <PulseLoader iconSize="1.5rem" />
            </div>
        );

    if (order === 'ERROR')
        return (
            <React.Fragment>
                <DrawerHint error>An error occurred or map with the specified ID doesn't exist. </DrawerHint>
            </React.Fragment>
        );

    return (
        <React.Fragment>
            <StyledDrawerTitle>{order.orderNumber}</StyledDrawerTitle>
            <StyledDrawerSubTitle>by&nbsp;{order.company}</StyledDrawerSubTitle>
        </React.Fragment>
    );
};

export default OrderSidebar;
