import { useState, useEffect, useRef } from 'react';

export const useComponentVisible = (initialIsVisible: boolean) => {
    const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>(null);

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (e: { target: any }) => {
        if (ref?.current?.contains(e.target)) {
            return;
        }
        setIsComponentVisible(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        document.addEventListener('keydown', handleHideDropdown, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
};
