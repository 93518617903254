import { LatLng, LatLngBounds } from 'leaflet';
import ApiListings from '../../../api/api-listings';
import { TileLayerHash } from '../../../api/model';
import { LatLngBoundsHash } from '../../../lib/lat-lng-bounds-hash';
import { actionTypes as at } from './constants';
import { ListingDictionary, ListingGroup } from './model';

export const actionFetchMyMapsListingDictionary = (userId: string) => {
    return async (dispatch, getState) => {
        dispatch(actionFetchMyMapsListingDictionarySuccess(undefined));
        dispatch(actionFetchMyMapsListingDictionaryIsLoading(true));
        const tileLayerHash: TileLayerHash[] = await ApiListings.getMinimizedListings(undefined, userId, false);
        const tileLayerDictionary: ListingDictionary = new Map<string, ListingGroup>();
        let globalMaps: ListingGroup = {
            latlngBounds: new LatLngBounds(new LatLng(-85, -180), new LatLng(85, 180)),
            tileLayers: [],
        };

        tileLayerHash
            .map((t) => {
                return {
                    hash: new LatLngBoundsHash(t.latlngBounds).hash,
                    latlngBounds: t.latlngBounds,
                    listingId: t.listingId,
                    author: t.author,
                    title: t.title,
                };
            })
            .sort((a, b) => a.hash.localeCompare(b.hash))
            .forEach((t) => {
                if (t.latlngBounds.getNorth() > 84 && t.latlngBounds.getSouth() < -84) {
                    const existingGlobalMaps = globalMaps.tileLayers;
                    globalMaps = {
                        latlngBounds: globalMaps.latlngBounds,
                        tileLayers: [
                            { id: t.listingId, authorName: t.author || '', title: t.title || '' },
                            ...existingGlobalMaps,
                        ],
                    };
                }

                const existingValueForHash = tileLayerDictionary.get(t.hash);
                const existingTileLayersForHash = existingValueForHash ? existingValueForHash.tileLayers || [] : [];
                const newValueForHash: ListingGroup = {
                    latlngBounds: t.latlngBounds,
                    tileLayers: [
                        { id: t.listingId, authorName: t.author || '', title: t.title || '' },
                        ...existingTileLayersForHash,
                    ],
                };
                tileLayerDictionary.set(t.hash, newValueForHash);
            });
        dispatch(actionFetchMyMapsListingDictionaryIsLoading(false));
        dispatch(actionFetchMyMapsListingDictionarySuccess(tileLayerDictionary));
    };
};

const actionFetchMyMapsListingDictionaryIsLoading = (isLoading: boolean) => {
    return {
        type: at.FETCH_MY_MAPS_TILE_LAYER_DICTIONARY_LOADING,
        payload: isLoading,
    };
};

export const actionFetchMyMapsListingDictionarySuccess = (dictionary: ListingDictionary | undefined) => {
    return {
        type: at.FETCH_MY_MAPS_TILE_LAYER_DICTIONARY_SUCCESS,
        payload: dictionary,
    };
};

export const actionSetMyMapsListingLayerGroups = (listingGroups: ListingGroup[]) => {
    return {
        type: at.SET_MY_MAPS_VISIBLE_TILE_LAYER_GROUPS,
        payload: listingGroups,
    };
};

export const actionSetMyMapsHighlightedListingGroup = (listingGroup: ListingGroup | undefined) => {
    return {
        type: at.SET_MY_MAPS_HIGHLIGHTED_TILE_LAYER_GROUP,
        payload: listingGroup,
    };
};

export const actionSetMyMapsHighlightedListingId = (listingId: number | undefined) => {
    return {
        type: at.SET_MY_MAPS_HIGHLIGHTED_TILE_LAYER_ID,
        payload: listingId,
    };
};
