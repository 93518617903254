export const actionTypes = {
    SIDEDRAWER_MODE: 'SIDEDRAWER/MODE',
    SIDEDRAWER_OPEN: 'SIDEDRAWER/OPEN',
    SIDEDRAWER_COLLAPSE: 'SIDEDRAWER/COLLAPSE',

    CATEGORY_MODE: 'SIDEDRAWER/CATEGORY_MODE',

    FEATURED_CATEGORY_LISTINGS: 'SIDEDRAWER/FEATURED_CATEGORY_LISTINGS',
    FEATURED_CATEGORY_LOADING: 'SIDEDRAWER/FEATURED_CATEGORY_LOADING',
};
