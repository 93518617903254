import styled from 'styled-components';

import { Modal, ModalBody } from 'reactstrap';
import ImageSlider from 'react-image-comparison-slider';
import { StyledButton } from '../styled-button';

interface ComparisonModalProps {
    isOpen: boolean;
    toggle: () => void;
    title?: string;
    description?: string;
    image1: string;
    image2: string;
    image1_label?: string;
    image2_label?: string;
}

const ComparisonModal = (props: ComparisonModalProps) => {
    return (
        <ModalContainer isOpen={props.isOpen}>
            <Body>
                <Title>{props.title}</Title>
                <Description>{props.description}</Description>

                <ImageContainer>
                    <ImageSlider
                        image1={props.image2}
                        image2={props.image1}
                        sliderWidth={3}
                        sliderColor="#EED926"
                        handleColor="#00A2FF"
                        handleBackgroundColor="black"
                        leftLabelText={props.image1_label}
                        rightLabelText={props.image2_label}
                        showPlaceholder={true}
                    />
                </ImageContainer>
                <Button className="comparison-modal-button" onClick={props.toggle}>
                    CLOSE
                </Button>
            </Body>
        </ModalContainer>
    );
};

export default ComparisonModal;

const ModalContainer = styled(Modal)`
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 20vh;
`;

const Body = styled(ModalBody)`
    position: absolute;
    border-radius: 4px;
    margin-left: -200px;
    width: 900px;
    background-color: rgba(0, 0, 0, 0.95);

    @media only screen and (max-height: 750px) {
        width: 750px;
        margin-left: -200px;
    }
`;

const Title = styled.h1`
    color: #00a2ff;
    font-weight: bold;
`;

const Description = styled.h4`
    color: #00a2ff;
`;

const ImageContainer = styled.div`
    width: 850px;
    height: 600px;
    max-width: 850px;
    max-height: 600px;
    z-index: 999;
    margin: auto;

    @media only screen and (max-height: 750px) {
        width: 700px;
        height: 450px;
    }
`;

const Button = styled(StyledButton)`
    margin: 15px auto;
`;
