import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationDTO } from '../../../api/model';
import { useComponentVisible } from '../../../lib/use-component-visible';
import Analytics from '../../../lib/user-analytics';
import { actionIsNotificationsOpen } from '../../../store/Notifications/actions';
import { selectNotifications, selectNotificationsOpen } from '../../../store/Notifications/selectors';
import {
    actionSetDisplayAnnotations,
    actionSetEditingComment,
    actionSetReplyingToComment,
    actionSetResetCommentSection,
    actionSetSelectedCommentId,
} from '../../../store/SocialMapping/actions';
import { NotificationBell } from './notification-bell';
import { NotificationsList } from './notifications-list';

export const Notifications = (): JSX.Element => {
    const notificationList: NotificationDTO[] = useSelector(selectNotifications);
    const isNotificationsOpen: boolean = useSelector(selectNotificationsOpen);
    const dispatch = useDispatch();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    useEffect(() => {
        if (isComponentVisible && !isNotificationsOpen) {
            dispatch(actionSetSelectedCommentId(''));
            dispatch(actionSetDisplayAnnotations(''));
            dispatch(actionSetReplyingToComment(false));
            dispatch(actionSetEditingComment(false));
            dispatch(actionSetResetCommentSection(true));
        }
    }, [isComponentVisible, isNotificationsOpen, dispatch]);

    useEffect(() => {
        if (isNotificationsOpen) {
            setIsComponentVisible(false);
            dispatch(actionIsNotificationsOpen(false));
            Analytics.Event('Notifications', 'Viewing notifications');
        }
    }, [isNotificationsOpen, dispatch, setIsComponentVisible]);

    return (
        <div ref={ref}>
            <NotificationBell
                toggleNotifications={() => setIsComponentVisible(!isComponentVisible)}
                notificationList={notificationList}
                showNotifications={isComponentVisible}
            />
            {isComponentVisible ? (
                <NotificationsList showNotifications={isComponentVisible} notificationList={notificationList} />
            ) : null}
        </div>
    );
};
