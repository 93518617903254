import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiFeaturedContent from '../../api/api-featured-content';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-container';
import SearchContentFixedMasonary, { FixedMasonaryItem } from './search-content-fixed-masonary';


interface SearchContentWhatsHotProps {
    handleSelectListing: (id: number) => void;
}

const SearchContentWhatsHot = (props: SearchContentWhatsHotProps) => {

    const [hottestMaps, setHottestMaps] = useState<FixedMasonaryItem[]>([]);

    useEffect(() => {
        ApiFeaturedContent.getHottestMaps().then((results) => {
            const items: FixedMasonaryItem[] = results.map(t => {
                return {
                    id: t.id,
                    authorName: t.userName,
                    title: t.title
                }
            })
            setHottestMaps(items);
        })
    }, []);

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/emoji-fire.svg" />
                Discover the <span>hottest</span> maps
            </SegmentTitle>
            {hottestMaps.length === 0 ?
                <SearchContentLoadingFill />
                :
                <SearchContentFixedMasonary items={hottestMaps} handleSelectListing={props.handleSelectListing} />
            }

        </SegmentContainer>
    );
};

export default SearchContentWhatsHot;

const SearchContentLoadingFill = styled.div`
    height: 400px;
`;

