import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Category, ListingCategories } from '../../api/model';
import { actionSetSelectedCategory } from '../../store/Map/MapSelection/actions';
import { selectSelectedCategory } from '../../store/Map/MapSelection/selectors';
import SoarCarousel from '../Shared/soar-carousel';

const SearchResultsCategoryFilter = () => {
    const dispatch = useDispatch();
    const selectedCategory = useSelector(selectSelectedCategory);
    const [isDragging, setIsDragging] = useState(false);

    const setSelectedCategory = (category: Category | undefined) => dispatch(actionSetSelectedCategory(category));

    if (selectedCategory) {
        return <React.Fragment />;
    }

    return (
        <FilterContainer>
            <CategoryFilterTitle>Filter by category </CategoryFilterTitle>
            <CarouselContainer>
                <SoarCarousel dragHandler={setIsDragging}>
                    {Object.values(ListingCategories).map((category) => {
                        return (
                            <CategoryFilterItem
                                onClick={() => {
                                    if (!isDragging) {
                                        setSelectedCategory(category);
                                    }
                                }}
                                key={category.title}
                            >
                                {category.title}
                            </CategoryFilterItem>
                        );
                    })}
                </SoarCarousel>
            </CarouselContainer>
        </FilterContainer>
    );
};

export default SearchResultsCategoryFilter;

const FilterContainer = styled.div`
    margin: 15px 0px 15px 30px;
    background: none;
    padding: 0px;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
`;

const CategoryFilterTitle = styled.span`
    color: #eed923;
    font-size: 1.2rem;
    width: 155px;
    padding-top: 4px;
`;

const CategoryFilterItem = styled.span`
    border-radius: 6px;
    margin: 2px 4px;
    padding: 1px 5px 3px 5px;
    text-align: center;
    font-size: 1rem;
    border: 1px solid #00a2ff;
    background: #191a1a;
    color: white;
    display: block;

    :hover {
        background: #636767;
    }
`;

const CarouselContainer = styled.div`
    width: calc(100% - 165px);
    margin-left: 0px;
    padding-right: 26px;
    position: relative;
`;
