import { actionTypes as at } from './constants';
import { ListingDTO } from '../../../api/model';
import { Action } from '../../root-reducer';

interface StoreMapSubdomain {
    tileLayers: ListingDTO[];
    activeTileLayer: ListingDTO | undefined;
}

const initialState: StoreMapSubdomain = {
    tileLayers: [],
    activeTileLayer: undefined,
};

export default (state: StoreMapSubdomain = initialState, action: Action): StoreMapSubdomain => {
    switch (action.type) {
        case at.SUBDOMAIN_FETCH_TILE_LAYER:
            return state;

        case at.SUBDOMAIN_FETCH_TILE_LAYER_SUCCESS:
            return {
                ...state,
                tileLayers: action.payload,
            };

        case at.SUBDOMAIN_TILE_LAYER_SET_ACTIVE:
            return {
                ...state,
                activeTileLayer: action.payload,
            };

        case at.SUBDOMAIN_TILE_LAYER_SET_INACTIVE:
            return {
                ...state,
                activeTileLayer: undefined,
            };

        default:
            return state;
    }
};
