import { AppState } from '../root-reducer';

export const selectActiveAnnotationString = (state: AppState) => state.socialMappingDomain.annotationString;

export const selectReplyingToComment = (state: AppState) => state.socialMappingDomain.replyingToComment;

export const selectUpdateAnnotations = (state: AppState) => state.socialMappingDomain.updateAnnotations;

export const selectAnnotationCommentId = (state: AppState) => state.socialMappingDomain.commentId;

export const selectResetAnnotations = (state: AppState) => state.socialMappingDomain.resetAnnotations;

export const selectEditComment = (state: AppState) => state.socialMappingDomain.editingComment;

export const selectComment = (state: AppState) => state.socialMappingDomain.comment;
