import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSelectedCategory, selectSelectedContinent } from '../../store/Map/MapSelection/selectors';

const SearchResultsNoFilter = () => {

    const selectedContinent = useSelector(selectSelectedContinent);
    const selectedCategory = useSelector(selectSelectedCategory);

    if (selectedCategory && selectedContinent) return <Spacer />

    return <React.Fragment />
};

export default SearchResultsNoFilter;

const Spacer = styled.div`
    height: 15px;
`;