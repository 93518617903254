import React, { useEffect } from 'react';
import styled from 'styled-components';
import { selectUploadToSuperMapError, selectUploadToSuperMapStatusReport } from '../../store/Dashboard/selectors';
import { toast } from 'react-toastify';
import { Progress, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { StatusReport } from '../../store/Dashboard/model';

const DISMISS_PROGRESSBAR_TIMEOUT = 4000;

const MapUploadStatus = () => {
    const uploadError = useSelector(selectUploadToSuperMapError);
    const statusReport = useSelector(selectUploadToSuperMapStatusReport);
    const [uploadList, setUploadList] = React.useState<StatusReport[]>([]);

    useEffect(() => {
        if (statusReport) {
            setUploadList((uploads) => {
                const index = uploads.findIndex((u) => u.key === statusReport.key);
                if (index === -1 && statusReport.status === 100) {
                    return uploads;
                } else if (index === -1) {
                    return [...uploads, statusReport];
                } else {
                    uploads[index] = statusReport;
                    return [...uploads];
                }
            });

            if (statusReport.status === 100 || statusReport.error) {
                setTimeout(() => {
                    setUploadList((list) => list.filter((l) => l.key !== statusReport.key));
                }, DISMISS_PROGRESSBAR_TIMEOUT);
            }
        }
    }, [statusReport]);

    useEffect(() => {
        if (uploadError) {
            toast.error('Error during upload: ' + uploadError);
        }
    }, [uploadError]);

    return (
        <React.Fragment>
            {uploadList.length > 0 && (
                <Container>
                    {uploadList.map((upload) => {
                        return (
                            <Card key={upload.key}>
                                <Row>
                                    <Col md={12}>
                                        <Text>
                                            {upload.status === 100
                                                ? `Congratulations! ${upload.file?.name} upload is complete.`
                                                : upload.error
                                                ? `Error uploading file: ${upload.error}`
                                                : upload.file?.name}
                                        </Text>
                                        <ProgressBar>
                                            <CProgress
                                                value={upload.status}
                                                color={upload.error ? 'danger' : undefined}
                                            />
                                        </ProgressBar>
                                    </Col>
                                </Row>
                            </Card>
                        );
                    })}
                </Container>
            )}
        </React.Fragment>
    );
};

export default MapUploadStatus;

const Container = styled.div`
    display: flex;
    flex-flow: column;
    border-radius: 4px;
    padding: 12px;
    padding-top: 0px;
    width: 450px;
    position: absolute;
    min-height: 0px;
    overflow-y: hidden;
    right: 70px;
    top: 102px;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.8);
`;

const Card = styled.div`
    overflow: hidden;
    color: white;
    margin: 10px 0 0 0;
    padding: 5px;
    border: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
`;

const Text = styled.div`
    color: white;
    margin-left: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ProgressBar = styled.div`
    min-height: 20px;
    margin: 0px 0px 0px 12px;
    color: white;
    overflow-y: auto;
`;

const CProgress = styled(Progress)`
    .progress-bar {
        background-color: #eed926;
    }
`;
