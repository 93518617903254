import React from 'react';
import styled, { keyframes } from 'styled-components';

interface SatelliteLoadingProps {
    customMessage?: string;
}

const SatelliteLoading = (props: SatelliteLoadingProps) => {
    return (
        <SatelliteLoadingContainer>
            <LoadingText>{props.customMessage ? props.customMessage : 'Waiting for satellites ...'}</LoadingText>
            <LoadingSatelliteIcon src="/assets/floating-drawer-icons/icon-UI-satellite-white.png" />
            <LoadingWave src="/assets/floating-drawer-loading-icons/loading-wave.svg" />
            <LoadingAntennaIcon src="/assets/floating-drawer-loading-icons/loading-antenna-icon.svg" />
        </SatelliteLoadingContainer>
    );
};

export default SatelliteLoading;

const SatelliteLoadingContainer = styled.div`
    min-height: 260px;
`;

const LoadingText = styled.p`
    margin-top: 40px;
    color: white;
    padding: 0;
    text-align: center;
`;

const LoadingSatelliteIcon = styled.img`
    bottom: 150px;
    right: 140px;
    position: absolute;
`;

const LoadingAntennaIcon = styled.img`
    position: absolute;
    bottom: 12px;
    left: 112px;
    width: 44px;
    height: 44px;
    opacity: 0.8;
`;

const WaveAnimation = keyframes`
    0% {
        bottom: 118px;
        right: 155px;
        transform: rotate(135deg) scale(0.3);
    } 95% {
        bottom: 20px;
        right: 265px;
        transform: rotate(135deg) scale(0.7);
    } 100% {
        bottom: 20px;
        right: 275px;
        transform: rotate(135deg) scale(0.2);
    }
`;
const LoadingWave = styled.img`
    bottom: 165px;
    right: 160px;
    position: absolute;
    transform: rotate(135deg) scale(0.7);
    opacity: 0.6;
    animation: ${WaveAnimation} 1.25s infinite linear;
`;
