import { LatLngBounds, LatLng } from 'leaflet';
import { ListingType, Review, ListingDTO } from '../../../api/model';

export const newGuineaGlacierBounds = new LatLngBounds(
    new LatLng(-4.091457528690613, 137.0804071426392),
    new LatLng(-4.023350348624906, 137.19709396362308)
);

export const HARD_CODED_ANIMATED_LAYER_START_ID = 900000;

const hamunLakeBoundingBox = new LatLngBounds(new LatLng(31.6653, 60.7158), new LatLng(29.6333, 63.0783));

export const basraOilFields: ListingDTO = {
    listingType: ListingType.ANIMATED,
    id: 900003,
    title: 'Basra Oil Fields Full Region 2009-2022',
    description:
        'Fires and Thermal Anomalies (Day and Night) 2009-2022. NASA-MODIS/Suomi 150km wide // 512x512px ~250m/px 54 frames. Contains modified MODIS and Suomi data provided by NASA-Worldview.',
    tags: ['animated map', 'oil fields', 'fire'],
    tileUrl: '/assets/animated-layers/basra-oil-fields.gif',
    dateUploaded: new Date(2022, 5, 4),
    filename: '/assets/animated-layers/basra-oil-fields.gif',
    boundingBox: new LatLngBounds(new LatLng(31.2190122, 46.9609305), new LatLng(30.0951546, 48.0841793)),
    totalLikes: 0,
    userName: 'Sérgio Augusto Jardim Volkmer',
    avatarUrl: 'https://avatar.soar.earth/cdcbeabf684648e395fdb42c3adaff51epc.png/preview',
    userId: '0x20086283df584134d9b1631edf5735620621e72b',
    categories: [],
    geometryWKT: '',
    metadata: '',
    createdAt: 0,
    priceUsd: 0,
};

export const nahrUmar: ListingDTO = {
    listingType: ListingType.ANIMATED,
    id: 900004,
    title: 'Nahr Umar Field 2013-2022',
    description:
        'Active full period 2013-2022. Landsat-8 + Sentinel-2 ~5km wide // 512x512px ~10m/px 36 frames. Contains modified Landsat-8 and Sentinel-2 data provided by Copernicus Sentinel-Hub.',
    tags: ['animated map', 'oil fields', 'fire'],
    tileUrl: '/assets/animated-layers/nahr-umar.gif',
    dateUploaded: new Date(2022, 5, 4),
    filename: '/assets/animated-layers/nahr-umar.gif',
    totalLikes: 0,
    boundingBox: new LatLngBounds(new LatLng(30.757754, 47.6479125), new LatLng(30.7272798, 47.6858914)),
    userName: 'Sérgio Augusto Jardim Volkmer',
    avatarUrl: 'https://avatar.soar.earth/cdcbeabf684648e395fdb42c3adaff51epc.png/preview',
    userId: '0x20086283df584134d9b1631edf5735620621e72b',
    categories: [],
    geometryWKT: '',
    metadata: '',
    createdAt: 0,
    priceUsd: 0,
};

export const westQurna: ListingDTO = {
    listingType: ListingType.ANIMATED,
    id: 900005,
    title: 'West Qurna Field 2013-2022',
    description:
        'Flames started 2014, between JAN/APR. Landsat-8 + Sentinel-2 ~9km wide // 512x512px ~20m/px 37 frames. Contains modified Landsat-8 and Sentinel-2 data provided by Copernicus Sentinel-Hub.',
    tags: ['animated map', 'oil fields', 'fire'],
    dateUploaded: new Date(2022, 5, 4),
    tileUrl: '/assets/animated-layers/west-qurna.gif',
    filename: '/assets/animated-layers/west-qurna.gif',
    totalLikes: 0,
    boundingBox: new LatLngBounds(new LatLng(31.0353837, 47.2440806), new LatLng(30.9591235, 47.3393738)),
    userName: 'Sérgio Augusto Jardim Volkmer',
    avatarUrl: 'https://avatar.soar.earth/cdcbeabf684648e395fdb42c3adaff51epc.png/preview',
    userId: '0x20086283df584134d9b1631edf5735620621e72b',
    categories: [],
    geometryWKT: '',
    metadata: '',
    createdAt: 0,
    priceUsd: 0,
};

export const rumailaNorth: ListingDTO = {
    listingType: ListingType.ANIMATED,
    //  type: 'Animated Layer',
    id: 900006,
    title: 'Rumaila North, flames highlighting 2022-04-05',
    description:
        'Alternate Near Natural Colors x SWIR Enhancement, for highlighting location of flames. Sentinel-2 2022-04-05. Contains modified data provided by Copernicus Sentinel-Hub.',
    tags: ['animated map', 'oil fields', 'fire', 'iraq'],
    dateUploaded: new Date(2022, 5, 11),
    tileUrl: '/assets/animated-layers/RumailaNorth-2022-04-05-TC-SWIR.gif',
    filename: '/assets/animated-layers/RumailaNorth-2022-04-05-TC-SWIR.gif',
    totalLikes: 0,
    boundingBox: new LatLngBounds(new LatLng(30.7159608, 47.1491984), new LatLng(30.3904455, 47.5271967)),
    userName: 'Sérgio Augusto Jardim Volkmer',
    avatarUrl: 'https://avatar.soar.earth/cdcbeabf684648e395fdb42c3adaff51epc.png/preview',
    userId: '0x20086283df584134d9b1631edf5735620621e72b',
    categories: [],
    geometryWKT: '',
    metadata: '',
    createdAt: 0,
    priceUsd: 0,
};

export const zubayr: ListingDTO = {
    listingType: ListingType.ANIMATED,
    id: 900007,
    title: 'Zubayr, flames highlighting 2022-04-05',
    description:
        'Alternate Near Natural Colors x SWIR Enhancement, for highlighting location of flames. Sentinel-2 2022-04-05. Contains modified data provided by Copernicus Sentinel-Hub.',
    tags: ['animated map', 'oil fields', 'fire', 'iraq'],
    dateUploaded: new Date(2022, 5, 11),
    tileUrl: '/assets/animated-layers/Zubayr-2022-04-05-TC-SWIR.gif',
    filename: '/assets/animated-layers/Zubayr-2022-04-05-TC-SWIR.gif',
    totalLikes: 0,
    boundingBox: new LatLngBounds(new LatLng(30.5298317, 47.5738833), new LatLng(30.3211214, 47.8159259)),
    userName: 'Sérgio Augusto Jardim Volkmer',
    avatarUrl: 'https://avatar.soar.earth/cdcbeabf684648e395fdb42c3adaff51epc.png/preview',
    userId: '0x20086283df584134d9b1631edf5735620621e72b',
    categories: [],
    geometryWKT: '',
    metadata: '',
    createdAt: 0,
    priceUsd: 0,
};

export const hamunLake: ListingDTO = {
    listingType: ListingType.ANIMATED,
    id: 900002,
    title: 'Hamun lake - Current state after 2022 rains',
    description:
        'After recent rains and snowfall in the beginning of 2022 in the Sistan endorheic water basin, in the border of Iran and Afghanistan, the complex of Hamun Lake (hamun is the local generic word for wetlands or marshes), the largest freshwater lake in Iran, which has been drying since long time, received a new hope. However, its situation is not that simple. The lake depends on water sources of various tributary rivers, from different zones.',
    tags: ['animated map', 'wetlands', 'change over time'],
    tileUrl: '/assets/animated-layers/hamun-lake-slow.gif',
    dateUploaded: new Date(1643297564 * 1000),
    filename: '/assets/animated-layers/hamun-lake-slow.gif',
    boundingBox: hamunLakeBoundingBox,
    totalLikes: 0,
    userName: 'Sérgio Augusto Jardim Volkmer',
    avatarUrl: 'https://avatar.soar.earth/cdcbeabf684648e395fdb42c3adaff51epc.png/preview',
    userId: '0x20086283df584134d9b1631edf5735620621e72b',
    categories: [],
    geometryWKT: '',
    metadata: '',
    createdAt: 0,
    priceUsd: 0,
};

export const newGuineaGlacier: ListingDTO = {
    listingType: ListingType.ANIMATED,
    id: 900001,
    title: "Puncak Jaya's glaciers retreating 1850-2021, Indonesia",
    description: `In this sequence we have rendered snowlines since 1850, and satellite images since 1974. It can be seen the overall loss of 99% of ice coverage, indicating an irreversible process of disappearance to be completed in coming years. // Sources: 1974-2021: Landsat-1/4/5, Sentinel-2; at soar.earth , sentinel-hub.com , earthexplorer.usgs.gov // Before 1974: I. Allison & J. A. Peterson, 1989 ( https://pubs.er.usgs.gov/publication/pp1386H ) ; M. L. Prentice & S. Glidden, 2010 ( https://openresearch-repository.anu.edu.au/handle/1885/229100?mode=full ) // Processed for soar.earth by: Sérgio A. J. Volkmer; Christopher Lowe `,
    tags: ['Glacier', 'glaciology', 'climate change', 'Indonesia'],
    tileUrl: '/assets/animated-layers/new-guinea-glacier.gif',
    dateUploaded: new Date(2021, 8, 17),
    filename: 'new-guinea-glacier',
    boundingBox: newGuineaGlacierBounds,
    totalLikes: 31,
    review: Review.APPROVED,
    userName: 'Sérgio Augusto Jardim Volkmer',
    avatarUrl: 'https://avatar.soar.earth/cdcbeabf684648e395fdb42c3adaff51epc.png/preview',
    userId: '0x20086283df584134d9b1631edf5735620621e72b',
    categories: [],
    geometryWKT: '',
    metadata: '',
    createdAt: 0,
    priceUsd: 0,
};

export const hardCodedAnimatedLayers: ListingDTO[] = [newGuineaGlacier, hamunLake];

export const BBCNewsAnimatedLayers: ListingDTO[] = [basraOilFields, westQurna, nahrUmar, zubayr, rumailaNorth];
