import styled from 'styled-components';
import { Button, Content, TeaserIcon, TeaserTitle } from '../satellite-drawer-styles';

interface SatelliteOrderPurchaseCompleteProps {
    handleClickFinished: () => void;
}

const SatelliteOrderPurchaseComplete = (props: SatelliteOrderPurchaseCompleteProps) => {
    return (
        <Content>
            <TeaserTitle>Congratulations your order is complete!</TeaserTitle>
            <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
            <List>
                <ListItem>
                    Once our team of geospatial experts have prepared and mosaiced the imagery you will receive a
                    notification email.
                </ListItem>
                <ListItem>Expect a response in 2-3 working days</ListItem>
                <ListItem>You can track the progress of the order from your user dashboard</ListItem>
                <ListItem>Once it is ready you will be able to download or view in Soar</ListItem>
            </List>
            <Button onClick={() => props.handleClickFinished()}>OK</Button>
        </Content>
    );
};

export default SatelliteOrderPurchaseComplete;

const List = styled.ul`
    margin: 0 0 22px 0;
`;

const ListItem = styled.li`
    color: white;
`;