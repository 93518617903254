import { ListingDTO } from '../../../api/model';
import { actionTypes as at } from './constants';

export const actionSetAnimatedLayerActive = (layer: ListingDTO | undefined) => {
    return {
        type: at.ANIMATED_LAYER_SET_ACTIVE,
        payload: layer,
    };
};

export const actionSetAnimatedLayers = (layers: ListingDTO[]) => {
    return {
        type: at.ANIMATED_LAYERS,
        payload: layers,
    };
};
