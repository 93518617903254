import { AppState } from '../../root-reducer';

export const selectMyMapsListingDictionary = (state: AppState) => state.myMapSelectionDomain.listingMyMapsDictionary;
export const selectMyMapsListingDictionaryIsLoading = (state: AppState) =>
    state.myMapSelectionDomain.listingMyMapsDictionaryIsLoading;

export const selectMyMapsVisibleListingGroups = (state: AppState) =>
    state.myMapSelectionDomain.visibleListingMyMapGroups;
export const selectMyMapsHighlightedListingGroup = (state: AppState) =>
    state.myMapSelectionDomain.highlightedListingMyMapGroup;
export const selectMyMapsHighlightedListingId = (state: AppState) =>
    state.myMapSelectionDomain.highlightedListingMyMapId;
