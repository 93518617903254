import ApiFeatured from '../../api/api-featured';
import { actionTypes as at } from './constants';
import { SideDrawerMode } from './model';

export const actionSetSideDrawerModeAction = (sideDrawerMode: SideDrawerMode) => {
    return {
        type: at.SIDEDRAWER_MODE,
        payload: sideDrawerMode,
    };
};

export const actionSetSideDrawerOpenAction = (sideDrawerOpen: boolean) => {
    return {
        type: at.SIDEDRAWER_OPEN,
        payload: sideDrawerOpen,
    };
};

export const actionSetCategoryMode = (categoryKey: string | undefined) => {
    return {
        type: at.CATEGORY_MODE,
        payload: categoryKey,
    };
};

export const actionSelectFeaturedCategories = (category: string) => {
    return async (dispatch, getState) => {
        dispatch(actionFeaturedLoading(true));
        try {
            const featuredMaps = await ApiFeatured.getFeaturedMaps(category);
            if (featuredMaps) {
                dispatch(actionSetFeaturedSuccess(featuredMaps));
            }
        } catch (error) {
            // TODO handle error
        } finally {
            dispatch(actionFeaturedLoading(false));
        }
    };
};

const actionSetFeaturedSuccess = (featuredListings) => {
    return {
        type: at.FEATURED_CATEGORY_LISTINGS,
        payload: featuredListings,
    };
};

const actionFeaturedLoading = (isLoadingFeaturedListings: boolean) => {
    return {
        type: at.FEATURED_CATEGORY_LOADING,
        payload: isLoadingFeaturedListings,
    };
};

export const actionSetSideDrawerCollapseAction = (sideDrawerOpen: boolean) => {
    return {
        type: at.SIDEDRAWER_COLLAPSE,
        payload: sideDrawerOpen,
    };
};
