import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CSWHistoricalRequestCost } from '../../../../../api/model';

import ComparisonModal from '../../../../Shared/ImageComparisonModal/comparison-modal';
import PriceWithCurrency from '../../../../Shared/price-with-currency';
import Analytics, { ConversionEvent } from '../../../../../lib/user-analytics';
import SatelliteOrderVoucherInput from '../../OrderWorkflow/satellite-order-voucher-input';
import { Button } from '../../satellite-drawer-styles';

interface Skymap50PurchaseDetailsProps {
    isLoading: boolean;
    onConfirm: () => void;
    onEnterVoucher: (voucher: string) => void;
    cswCost: CSWHistoricalRequestCost | undefined;
    cswCostError: Error | undefined;
}

const FormattedPriceWithCurrency: React.FC<{ currency: string; amount: number }> = (props) => {
    const audUS = props.currency.toLowerCase() === 'aud';
    return (
        <>
            <PriceLabel>
                <PriceWithCurrency currency={props.currency} value={props.amount} />
            </PriceLabel>
            <GST>{audUS && `Inc GST`}</GST>
        </>
    );
};

const Skymap50PurchaseDetails = (props: Skymap50PurchaseDetailsProps) => {
    const [showVoucherInput, setShowVoucherInput] = useState(false);
    const [showImageComparisonModal, setShowImageComparisonModal] = useState(false);
    const [showVoucherValue, setShowVoucherValue] = useState(false);

    const areaInKmString = props.cswCost ? `${props.cswCost.areaInKm}km²` : '--km²';

    useEffect(() => {
        if (props.cswCost && props.cswCost.voucher && props.cswCost.voucherValueUsed) {
            setShowVoucherValue(true);
        } else {
            setShowVoucherValue(false);
        }
    }, [props.cswCost]);

    return (
        <SkyMap50NewCollectContainer>
            <ComparisonModal
                isOpen={showImageComparisonModal}
                toggle={() => setShowImageComparisonModal(false)}
                image1="/assets/skymap50-comparison/skymap50.png"
                image2="/assets/skymap50-comparison/sentinel.png"
                image1_label="SkyMap50"
                image2_label="Sentinel"
            />
            <CollectionTitle>Your order summary</CollectionTitle>

            <CollectionDetails>
                <CollectionItems>
                    <p>Satellite</p>
                    <p>SkyMap50</p>
                </CollectionItems>
                <CollectionCostDivider />
                <CollectionItems>
                    <p>Request Area</p>
                    <p>{areaInKmString}</p>
                </CollectionItems>
                <CollectionCostDivider />
                <CollectionItems>
                    <p>Pixel Resolution</p>
                    <Link
                        onClick={() => {
                            setShowImageComparisonModal(true);
                            Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked to show comparison modal');
                        }}
                    >
                        50cm/pixel
                    </Link>
                </CollectionItems>
                <CollectionCostDivider />
                {showVoucherValue && props.cswCost && props.cswCost.voucherValueUsed && (
                    <>
                        <CollectionItems>
                            <p>Voucher Value</p>
                            <p>{`${props.cswCost.currency === 'USD' ? 'US' : props.cswCost.currency
                                } $${props.cswCost.voucherValueUsed.toFixed(2)}`}</p>
                        </CollectionItems>
                        <CollectionCostDivider />
                    </>
                )}
                <CollectionItems>
                    <p>Total Price</p>
                    <CollectionCost>
                        {props.cswCost ? (
                            <FormattedPriceWithCurrency
                                currency={props.cswCost.currency || ''}
                                amount={props.cswCost.totalPrice}
                            />
                        ) : (
                            <NoCost>US$---</NoCost>
                        )}
                    </CollectionCost>
                </CollectionItems>
            </CollectionDetails>
            <CollectionButtonContainer>
                <VoucherButton disabled={!props.cswCost} onClick={() => setShowVoucherInput(true)}>
                    I HAVE A VOUCHER
                </VoucherButton>
                <Button
                    disabled={!props.cswCost}
                    onClick={() => {
                        props.onConfirm();
                        Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked to confirm order');
                        Analytics.Conversion(ConversionEvent.CONFIRM_ORDER);
                    }}
                >
                    {!props.cswCost && <i className="fa fa-spinner fa-spin" />} CONFIRM ORDER
                </Button>
            </CollectionButtonContainer>

            <SatelliteOrderVoucherInput
                isOpen={showVoucherInput}
                isLoading={props.isLoading}
                toggle={() => setShowVoucherInput(!showVoucherInput)}
                handleSubmitCoupon={props.onEnterVoucher}
                couponError={props.cswCostError ? `${props.cswCostError.message.replace('400: ', '')}` : ''}
                voucherDetails={props.cswCost}
            />
        </SkyMap50NewCollectContainer>
    );
};

export default Skymap50PurchaseDetails;

const SkyMap50NewCollectContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }

    div,
    p {
        color: white;
    }
`;

const CollectionTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
`;

const CollectionDetails = styled.div`
    padding: 0px 20px;
    font-size: 16px;
`;

const CollectionItems = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 5px;

    p:nth-child(2) {
        color: rgba(255, 255, 255, 0.6);
    }
`;

const CollectionCost = styled.p`
    div {
        color: #eed926 !important;
        font-size: 16px !important;
    }
`;

const CollectionButtonContainer = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: row;

    button {
        margin-bottom: 10px !important;
        margin-top: 10px !important;
    }
`;

const CollectionCostDivider = styled.div`
    border-top: 1px solid rgb(255, 255, 255, 0.3);
`;

const GST = styled.div`
    font-size: 1.2rem;
`;
const NoCost = styled.div`
    color: #eed926;
    font-size: 16px;
`;
const PriceLabel = styled.div`
    font-size: 16px;
`;

const Link = styled.a`
    color: rgba(255, 255, 255, 0.6) !important;
    text-decoration: underline !important;
    cursor: pointer;
`;

const VoucherButton = styled.button`
    background-color: transparent !important;
    color: white !important;
    border: 1px solid white !important;
    margin-top: 30px;
    margin-bottom: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1rem;
    border-radius: 4px;
    margin-left: 35px;
`;
