import React from 'react';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { StyledButton } from '../../Shared/styled-button';

const NoBookmarkedMaps = () => {
    const handleClickedFindMaps = () => {
        Analytics.Event('My Bookmarks', 'Clicked to find maps', 'Existing User');
        UriHelper.navigateToPath(`/maps`);
    };

    return (
        <React.Fragment>
            <NoBookmarksCTA>
                <NoBookmarksCTAImage src="assets/sidebar-icons/bookmark-white.png" />
                <NoBookmarksText>Start bookmarking your favourite maps!</NoBookmarksText>
            </NoBookmarksCTA>
            <NoBookmarksButton
                onClick={() => {
                    handleClickedFindMaps();
                }}
            >
                <MyMapsUploadIcon src="/assets/floating-drawer-icons/maps-icon-black.svg" />
                Find Maps!
            </NoBookmarksButton>
        </React.Fragment>
    );
};

export default NoBookmarkedMaps;

const NoBookmarksText = styled.div`
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    text-align: center;
`;

const NoBookmarksCTA = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px;
`;

const NoBookmarksCTAImage = styled.img`
    width: 24px;
    height: 24px;
    margin: auto 10px auto 0px;
`;

const NoBookmarksButton = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    width: 96%;
    text-transform: uppercase;
`;

const MyMapsUploadIcon = styled.img`
    width: 25px;
    margin: -4px 7px 0px 5px;
`;
