import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ApiAnalytics from '../../../api/api-analytics';
import ApiComments from '../../../api/api-comments';
import { AnalyticsAction, AnalyticsNote, NotificationDTO } from '../../../api/model';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import {
    actionSetDisplayAnnotations,
    actionSetReplyingToComment,
    actionSetResetCommentSection,
    actionSetSelectedComment,
    actionSetSelectedCommentId,
    actionSetUpdateAnnotations,
} from '../../../store/SocialMapping/actions';

enum CommentType {
    COMMENT = 'COMMENT',
    COMMENT_REPLY = 'COMMENT_REPLY',
}

interface NotificationCardProps {
    notification: NotificationDTO;
    setSelected: () => void;
    isSelected: boolean | undefined;
}

export const NotificationCard = (props: NotificationCardProps): JSX.Element => {
    const { notification, setSelected } = props;
    const dispatch = useDispatch();
    const location = useLocation();

    const [details, setDetails] = useState<string>('');
    const [hasViewed, setHasViewed] = useState<boolean>(false);

    const user = notification.userName ? notification.userName : 'A Soar User';
    useEffect(() => {
        if (notification.type === CommentType.COMMENT) {
            const userCommented = `commented on your map`;
            setDetails(userCommented);
        } else if (notification.type === CommentType.COMMENT_REPLY) {
            const userReplied = `replied to your comment`;
            setDetails(userReplied);
        }
        if (notification.viewedAt !== undefined) {
            setHasViewed(true);
        }
    }, [notification]);

    const handleClickedNotification = (e) => {
        e.preventDefault();
        setSelected();
        setHasViewed(true);

        if (location && location.pathname.split('/')[2] !== notification.listingId.toString()) {
            UriHelper.navigateToMap(notification.listingId);
            ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.SEARCH, notification.listingId);
            Analytics.Event('Notifications', 'Clicked to view notification', notification.listingId);
        }

        if (notification.commentId) {
            dispatch(actionSetResetCommentSection(true));
            ApiComments.getCommentById(notification.commentId).then((comment) => {
                if (comment?.id) {
                    dispatch(actionSetReplyingToComment(true));
                    dispatch(actionSetSelectedComment(comment));
                    dispatch(actionSetSelectedCommentId(comment.id));
                    dispatch(actionSetDisplayAnnotations(comment.annotations));
                    dispatch(actionSetUpdateAnnotations(true));
                }
            });
        }
    };

    return (
        <Container onClick={handleClickedNotification} data-cy="notification-card">
            <Row>
                <Icon hasViewed={hasViewed} />
                <Username>{user}&nbsp;</Username>
                <Prose>{details}</Prose>
            </Row>

            {notification.content ? (
                <Row>
                    <Content hasViewed={hasViewed}>{`"${notification.content}"`}</Content>
                </Row>
            ) : null}

            <Row>
                <DateText>{`${moment(new Date(notification.createdAt * 1000)).fromNow()}`}</DateText>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    margin: 7px 5px 5px 7px;
    padding: 6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Icon = styled.div<{ hasViewed: boolean }>`
    background: ${(props) => (props.hasViewed ? 'rgba(255, 255, 255, 0.1);' : '#eed926')};
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 10px;
    height: 10px;
    margin: 6px 6px 0 0;
    min-width: 10px;
`;

const Username = styled.span`
    font-size: inherit;
    color: #eed926;
    margin: 0px;
    overflow-wrap: anywhere;
`;

const Prose = styled.span`
    color: rgba(255, 255, 255, 0.7);
`;

const Content = styled.p<{ hasViewed: boolean }>`
    color: ${(props) => (props.hasViewed ? 'rgba(255, 255, 255, 0.3);' : 'white;')};
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0px 0px 3px 0px;
    width: 100%;
    white-space: nowrap;
`;

const DateText = styled.p`
    color: rgba(255, 255, 255, 0.3);
    font-size: 10px;
    margin: 0px;
    text-align: right;
    line-height: 0.25;
    width: 100%;
`;
