import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSelectedCategory, selectSelectedContinent, selectSelectedSearchTerm } from '../../store/Map/MapSelection/selectors';


const SearchResultsBreadcrumbs = () => {

    const selectedCategory = useSelector(selectSelectedCategory);
    const selectedContinent = useSelector(selectSelectedContinent);
    const selectedSearchTerm = useSelector(selectSelectedSearchTerm);

    const isContinent = selectedContinent && selectedContinent.continent ? true : false;
    const isCategory = selectedCategory !== undefined;
    const isSearchTerm = selectedSearchTerm !== undefined && selectedSearchTerm.length > 0;

    const continent = (): string => {
        return selectedContinent && selectedContinent.continent ? selectedContinent.continent.name : ''
    }

    const category = (): string => {
        return selectedCategory ? selectedCategory.title : ''
    }

    if (isContinent && isCategory && isSearchTerm) {
        return (
            <React.Fragment>
                <ContinentSpan>{continent()}</ContinentSpan>, <CategorySpan>{category()}</CategorySpan> and <SearchTermSpan>{selectedSearchTerm}</SearchTermSpan>
            </React.Fragment>
        )
    }

    if (isContinent && isCategory && !isSearchTerm) {
        return (
            <React.Fragment>
                <ContinentSpan>{continent()}</ContinentSpan> and <CategorySpan>{category()}</CategorySpan>
            </React.Fragment>
        )
    }

    if (isContinent && !isCategory && isSearchTerm) {
        return (
            <React.Fragment>
                <ContinentSpan>{continent()}</ContinentSpan> and <SearchTermSpan>{selectedSearchTerm}</SearchTermSpan>
            </React.Fragment>
        )
    }

    if (isContinent && !isCategory && !isSearchTerm) {
        return (
            <React.Fragment>
                <ContinentSpan>{continent()}</ContinentSpan>
            </React.Fragment>
        )
    }

    if (!isContinent && isCategory && isSearchTerm) {
        return (
            <React.Fragment>
                <CategorySpan>{category()}</CategorySpan> and <SearchTermSpan>{selectedSearchTerm}</SearchTermSpan>
            </React.Fragment>
        )
    }

    if (!isContinent && isCategory && !isSearchTerm) {
        return (
            <React.Fragment>
                <CategorySpan>{category()}</CategorySpan>
            </React.Fragment>
        )
    }

    if (!isContinent && !isCategory && isSearchTerm) {
        return (
            <React.Fragment>
                <SearchTermSpan>{selectedSearchTerm}</SearchTermSpan>
            </React.Fragment>
        )
    }

    return <React.Fragment />
};

export default SearchResultsBreadcrumbs;

const ContinentSpan = styled.span`
    color: #eed923;
`;

const CategorySpan = styled.span`
    color: #00A2FF !important;
`;

const SearchTermSpan = styled.span`
    color: #737b7b !important;
`