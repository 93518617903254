import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ListingHelper from '../../lib/listing-helper';
import MapViewHistoryHelper from '../../lib/map-view-history-helper';
import Analytics from '../../lib/user-analytics';
import { selectListingDictionary } from '../../store/Map/MapSelection/selectors';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-container';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';

interface SearchContentHistoryProps {
    handleSelectListing: (id: number) => void;
}

const SearchContentHistory = (props: SearchContentHistoryProps) => {
    const listingDictionary = useSelector(selectListingDictionary);
    if (!listingDictionary) return <React.Fragment />;

    const listings = Array.from(listingDictionary.values()).flatMap((t) => t.tileLayers);

    const recentlyViewedMaps: CarouselItem[] = MapViewHistoryHelper.getViewedListingHistory()
        .map((t) => {
            return {
                id: t.id,
                previewUrl: ListingHelper.getPreviewUrlForListing(t.id),
                title: t.title,
                author: t.author,
            };
        })
        .filter((t) => listings.find((l) => l.id === t.id));

    if (!recentlyViewedMaps || recentlyViewedMaps.length < 2) return <React.Fragment />;

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/emoji-recent-map.svg" />
                Revisit maps you <span>recently viewed</span>
            </SegmentTitle>

            {/*@ts-ignore*/}
            <CarouselContainer>
                <SearchContentCarousel
                    items={recentlyViewedMaps}
                    height="180px"
                    handleSelectListing={(id) => {
                        props.handleSelectListing(id);
                        Analytics.Event('Search Bar', 'Clicked Map From History', id);
                    }}
                />
            </CarouselContainer>
        </SegmentContainer>
    );
};

export default SearchContentHistory;

const CarouselContainer = styled.div``;
