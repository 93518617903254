import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-leaflet';
import PreviewTile, { PreviewTileProps } from '../../../Shared/preview-tile';

const MultiPreviewTooltip = (props: PreviewTileProps) => {
    return (
        <React.Fragment>
            <StyledTooltip direction="top" sticky={true}>
                <Container>
                    <PreviewTile {...props} />
                </Container>
            </StyledTooltip>
        </React.Fragment>
    );
};

export default MultiPreviewTooltip;

const Container = styled.div`
    height: 48px;
    width: 320px;
    border-radius: 12px;
    overflow: hidden;
`;

const StyledTooltip = styled(Tooltip)<{ direction: string; sticky: boolean }>`
    position: absolute;
    background-color: transparent;
    border: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    margin: 0 0 0px 0;
    bottom: -36px;
    padding: 0;

    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 0px;
        left: 50%;
        border: 10px solid transparent;
        border-bottom: 0;
        border-top: 10px solid rgba(0, 0, 0, 0.7);
        transform: translate(-23%, calc(100% - 12px));
    }
`;
