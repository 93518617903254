import { useState } from 'react';
import styled from 'styled-components';
import countryList from 'react-select-country-list';
import { CountryInfo } from '../../../../api/model';
import Analytics, { ConversionEvent } from '../../../../lib/user-analytics';
import { Button, Content } from '../satellite-drawer-styles';

interface SatelliteOrderUserDetailsProps {
    handleSubmitUserDetails: (companyName: string, industry: Industry, country: CountryInfo) => void;
}

export enum Industry {
    Undefined = 'Select your industry',
    Mining = 'Mining / Oil / Gas',
    Agriculture = 'Agriculture',
    RealEstate = 'Real Estate',
    Security = 'Security / Defense',
    News = 'Media / News',
    Government = 'Government',
    Other = 'Other',
}

const SatelliteOrderUserDetails = (props: SatelliteOrderUserDetailsProps) => {
    const [companyName, setCompanyName] = useState<string>('');
    const [industry, setIndustry] = useState<Industry>(Industry.Undefined);
    const [industryValid, setIndustryValid] = useState(true);
    const [companyNameValid, setCompanyNameValid] = useState(true);
    const [countryName, setCountryName] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('');
    const [countryNameValid, setCountryNameValid] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (companyName.length < 2) {
            setCompanyNameValid(false);
            return;
        }

        if (countryCode.length < 2) {
            setCountryNameValid(false);
            return;
        }

        if (industry == Industry.Undefined) {
            setIndustryValid(false);
            return;
        }
        setSubmitting(true);
        const countryInfo: CountryInfo = {
            country: countryName,
            countryCode: countryCode,
        };
        props.handleSubmitUserDetails(companyName, industry, countryInfo);
        Analytics.Event('Satellite', 'Completed user details');
    };

    const handleCountryChange = (countryName: string, countryCode: string) => {
        setCountryName(countryName);
        setCountryCode(countryCode);
        setCountryNameValid(true);
        Analytics.Event('Satellite', 'Selected country', countryName);
    };

    return (
        <Content>
            <Title>Your Details</Title>

            <Form onSubmit={(e) => handleSubmit(e)}>
                <Label>Your name</Label>
                <TextField
                    placeholder="Enter your name"
                    type="text"
                    name="Organisation"
                    value={companyName}
                    onChange={(e) => {
                        setCompanyName(e.target.value);
                        setCompanyNameValid(true);
                    }}
                />

                {!companyNameValid ? <Error>Please enter your organisation or name</Error> : null}

                <Label>Your Industry</Label>
                <SelectField
                    name="Industry"
                    onChange={(value) => {
                        setIndustry(Industry[value.target.value]);
                        setIndustryValid(true);
                    }}
                >
                    {Object.keys(Industry).map((key) => {
                        return (
                            <option
                                onSelect={() => setIndustry(Industry[key])}
                                selected={industry.toString() === key}
                                key={key}
                                value={key}
                            >
                                {Industry[key]}
                            </option>
                        );
                    })}
                </SelectField>

                {!industryValid ? <Error>Please enter your industry</Error> : null}

                <Label>Your country</Label>
                <SelectField
                    name="Country"
                    onChange={(result) => {
                        const selected = JSON.parse(result.target.value);
                        handleCountryChange(selected.label, selected.value);
                        setCountryNameValid(true);
                    }}
                >
                    <option key="Your country" value={`{"value":"","label":""}`}>
                        Select your country
                    </option>
                    {countryList()
                        .getData()
                        .map((key) => {
                            if (key.label === 'Antarctica') return null;
                            return (
                                <option key={key.label} value={JSON.stringify(key)}>
                                    {key.label}
                                </option>
                            );
                        })}
                </SelectField>

                {!countryNameValid ? <Error>Please enter your country</Error> : null}
            </Form>

            <Divider />
            <SubmitButton
                disabled={submitting}
                onClick={(e) => {
                    handleSubmit(e);
                    Analytics.Event('Satellite Ordering', 'Clicked confirmed user details');
                    Analytics.Conversion(ConversionEvent.ADD_USER_DETAILS);
                }}
            >
                Confirm your details
            </SubmitButton>
        </Content>
    );
};

export default SatelliteOrderUserDetails;

const Title = styled.div`
    font-size: 24px;
    font-weight: 500;
    padding: 10px;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
`;

const Form = styled.form`
    display: block;
    padding: 0 12px 24px 12px;
`;

const Label = styled.label`
    color: white;
    display: block;
    font-size: 16px;
    margin-top: 18px;
`;

const TextField = styled.input`
    width: 100%;
    height: 40px !important;
    padding: 0px 12px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent !important;
    font-size: 16px;
    outline: none;
    color: rgba(255, 255, 255, 0.6) !important;

    :-webkit-autofill {
        :hover {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
            -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        }
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(255, 255, 255, 0.3) !important;
    }

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    :focus {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;

const Divider = styled.div`
    border-top: 1px solid rgb(255, 255, 255, 0.3);
`;

const SelectField = styled.select`
    width: 100%;
    height: 40px !important;
    padding: 0px 12px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent !important;
    font-size: 16px;
    outline: none;
    color: rgba(255, 255, 255, 0.6);

    option {
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0.8);
    }

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #343a40;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #888;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
        margin-top: 2px;
        margin-bottom: 0;
    }

    :focus,
    :active {
        background-color: transparent;
        border: 1px solid rgba(238, 217, 38, 0.6);
        box-shadow: 0 0 0 0.1rem rgba(238, 217, 38, 0.3);
        color: rgba(255, 255, 255, 0.6);
    }
`;

const Error = styled.p`
    color: red;
    margin: 2px 0 2px 0;
`;

const SubmitButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 10px;
`;
