import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { inputMaxValues } from '../../../../lib/limitation-constants';
import { selectActiveAnnotationString } from '../../../../store/SocialMapping/selectors';
import { StyledButton, StyledDarkButton } from '../../../Shared/styled-button';
import ActiveCommentAnnotationTags from './comment-annotation-tags';
import { CommentResultsDTO } from '../../../../api/model';
import { CommentResponseHandler } from './types';
import { toast } from 'react-toastify';
import ApiComments from '../../../../api/api-comments';
import UserHelper from '../../../../lib/user-helper';
import Analytics from '../../../../lib/user-analytics';

interface ActiveCommentEditProps {
    comment: CommentResultsDTO;
    handleCommentUpdate: CommentResponseHandler;
}

const ActiveCommentEdit = (props: ActiveCommentEditProps) => {
    const selectedAnnotationString = useSelector(selectActiveAnnotationString);

    const [commentText, setCommentText] = useState<string>(props.comment.text || '');

    const handleSubmitUpdate = () => {
        if (commentText.length >= inputMaxValues.MAX_DESCRIPTION_WORDS - 1) {
            toast.error(`Comments can only be ${inputMaxValues.MAX_DESCRIPTION_WORDS - 1} characters`);
            return;
        }

        if (commentText.length < 1 && UserHelper.getTagsFromAnnotationString(selectedAnnotationString).length < 1) {
            props.handleCommentUpdate(undefined, new Error('Empty'));
            return;
        }

        const updatedComment = {
            ...props.comment,
            text: commentText,
            annotations: selectedAnnotationString,
        };

        ApiComments.editComment(updatedComment.id, updatedComment)
            .then(() => {
                props.handleCommentUpdate(updatedComment);
                toast.dark('Comment updated');
                Analytics.Event('Comments', 'Updated comment', updatedComment.id);
            })
            .catch((err) => {
                props.handleCommentUpdate(undefined, err);
                toast.error('Error updating comment');
            });
    };

    const isCharLimitReached = commentText.length >= inputMaxValues.MAX_DESCRIPTION_WORDS - 1;

    return (
        <React.Fragment>
            <CommentItemBody onClick={(e) => e.stopPropagation()}>
                <CommentEditInputContainer isCharLimitReached={isCharLimitReached}>
                    <ListingCommentEditInput
                        id="edit-comment-input"
                        value={commentText}
                        onChange={(e) => {
                            setCommentText(e.target.value);
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        placeholder="Edit your comment..."
                    />
                    {selectedAnnotationString && (
                        <ActiveCommentAnnotationTags annotationString={selectedAnnotationString} />
                    )}
                </CommentEditInputContainer>
                <CommentListingEditContainerButtons>
                    <ListingCommentEditButton
                        disabled={isCharLimitReached}
                        onClick={(e) => {
                            handleSubmitUpdate();
                            e.stopPropagation();
                        }}
                    >
                        UPDATE
                    </ListingCommentEditButton>
                    <ListingCommentCancelButton
                        onClick={(e) => {
                            props.handleCommentUpdate();
                            e.stopPropagation();
                        }}
                    >
                        CANCEL
                    </ListingCommentCancelButton>
                </CommentListingEditContainerButtons>
            </CommentItemBody>
            <CharacterCount isCharLimitReached={isCharLimitReached}>
                {commentText.length}/{inputMaxValues.MAX_DESCRIPTION_WORDS - 1}
            </CharacterCount>
        </React.Fragment>
    );
};

export default ActiveCommentEdit;

interface CharacterCountProps {
    isCharLimitReached: boolean;
}

const CommentListingEditContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
`;

const ListingCommentEditInput = styled(TextareaAutosize)`
    background: none;
    border: none;
    width: 100%;
    outline: none;
    color: rgba(255, 255, 255, 0.6);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
    max-height: 200px;
    min-height: 25px;

    &::placeholder {
        color: rgba(255, 255, 255, 0.3) !important;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const CommentEditInputContainer = styled.div<CharacterCountProps>`
    color: white;
    background: transparent;
    border: ${(props) =>
        props.isCharLimitReached ? '2px solid rgba(255, 0, 0, 0.7);' : '1px solid rgba(255, 255, 255, 0.6);'};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0px 3px 0px 13px;
`;

const ListingCommentEditButton = styled(StyledButton)`
    margin: 7px 0px -10px 13px;
    font-size: 12px !important;
    border-radius: 6px !important;
    min-width: 55px;
    padding: 1px 6px !important;

    :focus {
        outline: none !important;
    }
`;

const ListingCommentCancelButton = styled(StyledDarkButton)`
    margin: 7px 0px -10px 13px;
    font-size: 12px !important;
    border-radius: 6px !important;
    min-width: 55px;
    padding: 1px 6px !important;
    margin-left: 7px;

    :focus {
        outline: none !important;
    }
`;

const CharacterCount = styled.span<CharacterCountProps>`
    color: ${(props) => (props.isCharLimitReached ? 'rgba(255, 0, 0, 0.7);' : 'rgba(255, 255, 255, 0.5);')};
    font-size: 10px;
    text-align: right;
    display: block;
    height: 0px;
    margin-right: 12px;
`;

const CommentItemBody = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px 20px 0px 2px;
`;
